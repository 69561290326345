import React, { useEffect, useState, useContext } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import AppRoutes from './route/AppRoutes';
import { serverGet, serverFormPost } from './middleware/http';
import { apiConst } from './services/constant/constant';
import { useProfile } from './services/utils/profileContext';
import { ImageCropContextProvider } from './services/utils/imageCrop';
import Layout from './theme/default/Layout';
import { DoctorProvider } from './services/Context/DoctorContext';
import { ecnSession, getAuthProfile } from './services/utils/validation';
import { EncounterProvider } from './services/Context/JoinCall';
import { LanguageResProvider } from './services/Context/LanguageContext';
import { useLayoutEffect } from 'react';
import { getSearchParams } from './services/utils/validation';
import ProgressContext from "./services/utils/progress";
export default function App() {
    const [applicationRoutes, setApplicationRoutes] = useState([]);
    const [islogin, setIslogin] = useState((/security\/(forgotpassword|login|resetpassword)/gm.test(window.location.pathname.toLowerCase())));
    const [profile, setprofile] = useState();
    const [isDocViewer, setIsDocViewer] = useState(false);
    // const [defaultPage,setDefaultPage] = useState(null)
    const [account, setAccount] = useState(null);
    const { updateProfile } = useProfile();
    const location = useLocation();
    const [IsAuth, setAuth] = useState(null);
    const { progParams } = useContext(ProgressContext);

    const clientId = getSearchParams("client_id");
    const clientSecret = getSearchParams("client_secret");
    const scopes = getSearchParams("scope");
    const redirect_uri = getSearchParams("redirect_uri");
    const response_type = getSearchParams("response_type");
    const to_redirect_uri = getSearchParams("to_redirect_uri");
    //console.log(JSON.stringify(base64sampleData))

    const initAccount = async () => {

        try {
            const _settingCodes = "DFP";

            const res = await serverGet(`${apiConst.gethost}?scodes=${_settingCodes}`);
            if (res?.status == 1) {
                setAccount(res?.data);


                let codes = (res?.data?.portalSettings?.filter(obj => obj.code === "THM")[0]?.value || "")?.split("~");
                if (codes?.length >= 4) {
                    var styles = {
                        "primary-color": codes[0],
                        "accent-color": codes[1],
                        "primary-color-rgb": codes[2],
                        "accent-color-rgb": codes[3],
                        "bs-body-font-family": !codes[4] ? "'Nunito', sans-serif" : codes[4].split("|")[1],
                    };

                    let _headerFooterBg;


                    if (Array.isArray(codes) && codes.length > 5) {
                        _headerFooterBg = JSON.parse(codes[5]);
                    } else {
                        _headerFooterBg = {};
                    }
                    setHeaderAndFooter(_headerFooterBg, styles["primary-color-rgb"]);

                    Object.keys(styles).forEach(function (key) {
                        document.documentElement.style.setProperty('--' + key, styles[key]);
                    })
                    var url = !codes[4] ? "https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap" : codes[4].split("|")[0];

                    loadCSS(url);
                }

                const app_routes = [...AppRoutes];

                const _defaultPage = res?.data?.portalSettings?.find(obj => obj.code === "DFP");
                //console.log(_defaultPage)
                if (_defaultPage?.value?.toUpperCase() == "SGI") {
                    const res = await serverGet(apiConst.getauthprofile);
                    app_routes[0].element = <Navigate to={res?.data?.auth ? "/dashboard" : "/security/login"} replace />
                }
                setApplicationRoutes(app_routes);
                //console.log(res)
            }
        } catch (e) {
            console.log(e);
        }

    }

    const setHeaderAndFooter = (value, defaultValue) => {

        const returnJoinvalues = (_value) => {
            if (Array.isArray(_value) && _value?.length == 3) {
                return _value?.join(",")
            }
            return false;
        }

        const styles = {
            "header-bg-color-rgb": returnJoinvalues(value?.headerBGRGB) || "255,255,255",
            "header-color": returnJoinvalues(value?.headerFontRGB) || "",
            "footer-bg-color-rgb": returnJoinvalues(value?.footerBGRGB) || defaultValue,
            "footer-color": returnJoinvalues(value?.footerFontRGB) || "255,255,255"
        };


        Object.entries(styles).forEach(([key, val]) => {
            document.documentElement.style.setProperty(`--${key}`, val);
        });

    }

    const checkSSOLogin = async () => {
        progParams(true);
        try {
            const response = await serverGet(apiConst.getauthprofile);

            if (response?.data?.auth) {

                if (clientId != null && typeof clientId != "undefined") {
                    // progParams(true);
                    let frmData = new FormData();
                    frmData.append("client_id", clientId);
                    frmData.append("client_secret", clientSecret);
                    frmData.append("grant_type", "client_credentials");
                    //axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
                    try {
                        await serverFormPost(`${apiConst.authorizeGet}`, frmData, false)
                            .then(res => {
                                console.log(`${redirect_uri}&code=${res.access_token}&state=b3BlbmlkY29ubmVjdA%3D%3D&to_redirect_uri=${to_redirect_uri}`);
                                //return window.location.href = `${redirect_uri}&code=${res.access_token}&state=b3BlbmlkY29ubmVjdA%3D%3D&to_redirect_uri=${to_redirect_uri}`;//&iss=https%3A%2F%2Flocalhost%3A44324%2F

                            });

                    } catch (err) { }
                }
            }

        } catch (err) {
            progParams(false);
        } finally {
            progParams(false);

        }
    }

    const loadCSS = (href) => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = href;
        document.head.appendChild(link);
    };


    const getprofile = async () => {

        if (getAuthProfile.getProfileAuth()) {
            // window.alert("calling")
            try {
                const res = await serverGet(apiConst.getuserprofile);
                if (res?.status == 1 && res?.data) {
                    //contact
                    const res_profile = JSON.parse(res?.data?.profile)
                    const res_contact = JSON.parse(res?.data?.contact)

                    const response = {
                        resProfile: res_profile?.data,
                        resContact: res_contact?.data,
                    }
                    updateProfile(response);
                    setprofile(response);
                    ecnSession(res_profile?.data?.id, "mid"); // {mid} user memberid
                    localStorage.setItem("memberId", res_profile?.data?.id);
                    getAuthProfile.saveProfileAuth();
                }
            } catch (error) {
                console.log(error);
            }
        }
    }


    const getResCode = async () => {
        try {
            const res = await serverGet(apiConst.getresponsecode + "/en-us");
            if (res?.data != null && res?.status == 1) {
                localStorage.setItem("errorMsg", JSON.stringify(res?.data));
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getAuth = async () => {

        try {
            const res = await serverGet(apiConst.getauthprofile);
            if (res?.status == 1) {
                if (res?.data?.auth) {
                    getAuthProfile.saveProfileAuth();
                } else {
                    getAuthProfile.clearProfileAuth();
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    useLayoutEffect(() => {
        const fetchData = async () => {
            try {
                await checkSSOLogin();
                await initAccount();
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [])


    useLayoutEffect(() => {
        const fetchData = async () => {
            try {
                let auth_res = getAuthProfile.getProfileAuth();

                if (auth_res == null || auth_res == undefined) {
                    const res = await serverGet(apiConst.getauthprofile);
                    auth_res = res?.data?.auth;
                }
                if (auth_res) {
                    getAuthProfile.saveProfileAuth();
                }

                setAuth(auth_res);


            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                //await getAuth();           
                await getResCode();
                //await initAccount();
                if (getAuthProfile.getProfileAuth() && !window.location.pathname.includes("security"))
                    await getprofile();
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {

        window.scrollTo(0, 0);

        if (location.pathname.includes("security")) {
            setIslogin(true);
        } else {
            setIslogin(false);
        }

        if (location.pathname.includes("docviewer") || location.pathname.includes("pagenotfound")) {
            setIsDocViewer(true);
        } else {
            setIsDocViewer(false);
        }

    }, [location]);


    return (
        <ImageCropContextProvider>
            <DoctorProvider>
                <EncounterProvider>
                    <LanguageResProvider>
                        <Layout islogin={islogin} isViewer={isDocViewer} account={account}>
                            <Routes>
                                {applicationRoutes?.length > 0 && applicationRoutes?.map((route, index) => {
                                    const { element, ...rest } = route;
                                    return <Route key={index} {...rest} element={element} />;
                                })}
                            </Routes>
                        </Layout>
                    </LanguageResProvider>
                </EncounterProvider>
            </DoctorProvider>
        </ImageCropContextProvider>
    )
}


