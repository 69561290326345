import React, { useContext, useEffect, useCallback, useState } from "react"
import { useForm, Controller } from 'react-hook-form';
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useLocation, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { subYears } from "date-fns";
import debounce from 'lodash.debounce';
import Moment from 'moment';
import { apiConst, appConst } from '../../../services/constant/constant'
import { serverGet, serverPost, serverPut } from '../../../middleware/http'
import { formValidation, getMsg, getSearchParams } from '../../../services/utils/validation';
import NotifyContext from "../../../services/utils/notify";
import ProgressContext from "../../../services/utils/progress";
import { useProfile } from "../../../services/utils/profileContext";
import setting from "../../../services/utils/data";
import ScheduleHeader from "../consultation/summary/ScheduleHeader";
import { useAccountSettings } from "../../../services/Context/AccSettingContext";
import { useLanguageRes } from '../../../services/Context/LanguageContext';
import { useManageResource } from "../../../services/Context/ManageResourceContext";
import ActionStatus from "../../../services/utils/actionStatus";

const AddProfile = () => {
    const { register, handleSubmit, control, setValue, formState: { errors } } = useForm();

    const [autoCompleteloading, setAutoCompleteloading] = useState(false);
    const [addressList, setAddressList] = useState([]);
    const [location, setLocation] = useState([]);
    const [profile, setprofile] = useState();
    const [accSetting, setAccSetting] = useState([]);
    const [timeZones, setTimeZones] = useState([]);
    const [timeZoneName, setTimeZoneName] = useState('');
    const [selectedDate, setSelectedDate] = useState();
    const [currentRes, setCurrentRes] = useState(null);

    const { resourceList, getCurrentResource } = useManageResource();
    const { notifyToast } = useContext(NotifyContext);
    const { progParams } = useContext(ProgressContext);
    const { updateProfile, getFamilyList, patientInfo } = useProfile();
    const { accountDate, patientMinimumAge } = useAccountSettings();
    const locationparams = useLocation();
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(locationparams.search);
    //const profile_auth = queryParams.get('a');
    const redirectUrl = sessionStorage.getItem("redirectUrl");
    const accountSettingCode = 'URS,CPI,TMZ,ECI';
    const { getLanguageContent } = useLanguageRes();


    const onSubmit = (data) => {

        const profileData = {
            firstName: data?.firstName || null,
            dob: Moment(data?.dob).format('YYYY-MM-DD') || null,
            email: profile?.resProfile.email,
            lastName: data?.lastName || null,
            genderId: data?.genderId || null,
            phone: data?.primaryPhone || null,
            timeZoneId: data?.timeZoneId || null,
            extRefId: data?.extRefId,
        }

        //const isContactEnabled = accSetting?.filter((list) => list?.settingCode == "ECI" && list?.value == "yes");

        if (currentRes?.isContact && patientInfo && !patientInfo?.parentId) {
            saveContactAndProfile(data, profileData);
        } else {
            saveProfile(profileData);
        }
    };

    const saveProfile = (profileData) => {
        progParams(true);
        serverPut(apiConst.userupdate, profileData).then((res) => {
            progParams(false);
            try {
                if (res?.status == 1) {
                    notifyToast(res.messageCode[0]);
                    getprofile();
                    navigateProfile();
                } else {
                    var error = JSON.parse(res?.data?.errorMessage);
                    notifyToast(error?.messageCode[0]);
                }
            } catch (e) {
                console.log(e);
            }
        }).catch((e) => {
            progParams(false)
            var error = JSON.parse(e?.response?.data?.errorMessage);
            notifyToast(error?.messageCode[0]);
        })
    }

    const saveContactAndProfile = (data, profileData) => {
        const contactData = {
            address1: data?.address1 || null,
            address2: data?.address2 || null,
            zipCode: data?.zipCode || null,
            //locationId: location[0]?.id,
            cityId: location[0]?.id,
            secondaryPhone: data?.secondaryPhone || null,
            memberId: profile?.resProfile?.id || null,
            typeId: 1,
        }
        if (profile?.resContact?.id) {
            contactData.id = profile?.resContact?.id;
        }
        progParams(true);
        serverPost(apiConst.updateprofile, { profile: profileData, contact: contactData }).then((res) => {
            progParams(false);
            try {
                if (res?.status == 1) {
                    notifyToast(res.messageCode[0]);
                    getprofile();
                    navigateProfile();
                } else {
                    var error = JSON.parse(res?.data?.errorMessage);
                    notifyToast(error?.messageCode[0]);
                }
            } catch (e) {
                console.log(e);
            }
        }).catch((e) => {
            var error = JSON.parse(e?.response?.data?.errorMessage);
            notifyToast(error?.messageCode[0]);
        })
    }

    const navigateProfile = async () => {
        try {
            const user_member_id = localStorage.getItem("memberId");

            const memberId = getSearchParams("memberid") || "";
            const visit_detail_page = getSearchParams("isrp"); // visit detail page navigate back to reason page to complete apointment t == (yes or true) else null or undefined
            const specialityId = getSearchParams("spid") ? `spid=${getSearchParams("spid")}` : '';
            const residingId = getSearchParams("rsid") ? `rsid=${getSearchParams("rsid")}` : '';
            const minPrice = getSearchParams("minP") ? `minP=${getSearchParams("minP")}` : '';
            const isDefaultSpecialty = getSearchParams("isds") ? `isds=${getSearchParams("isds")}` : '';
            const _brtype = getSearchParams("brtype");


            const document_Res = await serverGet(`${apiConst.doclist}${user_member_id}?recordid=${user_member_id}&gn=${appConst.cateogries.proofUpload}`); //to cehck id verification document upload length
            const document_length = (document_Res?.status != 1 || !document_Res?.data || document_Res?.data?.length < 0) ? true : false;
            const identificationProofUrl = `/idverification/upload?memberid=${memberId ? memberId : ""}${specialityId ? `&${specialityId}` : ""}${residingId ? `&${residingId}` : ""}${minPrice ? `&${minPrice}` : ""}${isDefaultSpecialty ? `&${isDefaultSpecialty}` : ""}&brtype=${_brtype}${getSearchParams("speicalityservice") ? `&speicalityservice=${getSearchParams("speicalityservice")}` : ""}`
            const client_profile_id = accSetting?.filter((list) => list?.settingCode == "CPI" && list?.value == 1) || [];

            if (visit_detail_page && visit_detail_page == "t") {
                const locationSearch = window.location.search;
                if (client_profile_id?.length > 0 && document_length) {
                    return navigate(`/idverification/upload${locationSearch}&isrp=t`);
                } else {
                    return navigate(`/reason${locationSearch}&isrp=t`);
                }
            } else if (redirectUrl && redirectUrl != '' && client_profile_id?.length > 0 && document_length) {
                return navigate(identificationProofUrl);
            } else if (client_profile_id?.length > 0 && document_length) {
                return navigate(identificationProofUrl);
            } else {
                if (redirectUrl && redirectUrl !== '') {
                    const url = JSON.parse(redirectUrl);
                    const _isFamily = await getFamilyList();
                    if (_isFamily) {
                        url.path = url?.familyMemberUrl;
                        sessionStorage.setItem("redirectUrl", JSON.stringify(url));
                    }

                    return navigate(`${url.path}`);
                } else {
                    navigate(`/dashboard`);
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    const getaddresslist = (data) => {
        setAutoCompleteloading(true);
        serverGet(`${apiConst.locationautocomplete}?kwd=${data}`).then((res) => {
            setAutoCompleteloading(false);
            try {
                if (res.data != null && res?.status == 1) {
                    const processedSuggestions = res?.data?.map(suggestion => ({
                        ...suggestion,
                        combinedLabel: `${suggestion?.name}${suggestion?.parent ? ',' : ''} ${suggestion?.parent}`,
                    }));
                    setAddressList(processedSuggestions);
                }
            } catch (error) {
                console.log(error);
            }
        })
    }

    const debouncedSave = useCallback(
        debounce((newValue) => getaddresslist(newValue), 1000),
        []
    );

    const updateValue = (newValue) => {
        if (newValue)
            debouncedSave(newValue);
    };

    const getprofile = () => {
        progParams(true);
        serverGet(apiConst.getuserprofile).then((res) => {
            progParams(false);
            try {
                if (res.data != null) {
                    //contact
                    const res_profile = JSON.parse(res.data.profile);
                    const res_contact = JSON.parse(res.data.contact);
                    const response = {
                        resProfile: res_profile.data,
                        resContact: res_contact.data,
                    }

                    updateProfile(response);
                    if (res_contact?.data?.cityId) {
                        setLocation([{
                            id: res_contact?.data?.cityId,
                            //parent: "New Hampshire, United States LocationName"
                            combinedLabel: res_contact?.data?.locationName
                        }])
                        setValue('locationId', res_contact?.data?.cityId);
                    }
                    //console.log(response)
                    setValue('firstName', res_profile?.data?.firstName);
                    setValue('lastName', res_profile?.data?.lastName);
                    setValue('genderId', res_profile?.data?.genderId);
                    setValue('dob', res_profile?.data?.dob ? new Date(Moment(res_profile?.data?.dob)) : null);
                    setValue('timeZoneId', res_profile?.data?.timeZoneId);
                    setValue('primaryPhone', res_profile?.data?.phone);
                    setValue('extRefId', res_profile?.data?.extRefId);
                    setValue('secondaryPhone', res_contact?.data?.secondaryPhone);
                    setValue('address1', res_contact?.data?.address1);
                    setValue('address2', res_contact?.data?.address2);
                    setValue('zipCode', res_contact?.data?.zipCode);
                    setprofile(response);
                }

            } catch (error) {
                console.log(error);
            }
        })
    }
    const getSetting = () => {
        serverGet(`${apiConst.getaccountsettings}/-/${encodeURIComponent(accountSettingCode)}`).then((res) => {
            try {
                if (res?.status == 1 && res?.data) {
                    if (Array.isArray(res.data) && res.data?.length > 0) {
                        setAccSetting(res.data);
                        //const is_contact_enabled = res.data?.filter((list) => list?.settingCode == "ECI" && list?.value == "1");
                        //if (is_contact_enabled?.length > 0) {
                        //    setIsContactEnabled(true);
                        //}
                    }
                }
            } catch (e) {
                console.log(e)
            }
        })
    }

    const getSettingData = () => {
        serverGet(apiConst.getsettingoptions + "/" + "TMZ").then((res) => {
            try {
                if (res.data != null && res.status == 1) {
                    setTimeZones(res.data);
                }
            } catch (error) {
                console.log(error);
            }
        })
    };

    const handleTimeZone = (e) => {
        let time_zones = [...timeZones];
        time_zones = time_zones.find(tz => tz?.settingOptionId == e.target.value);

        if (time_zones && time_zones?.displayText) {
            if (time_zones?.displayText?.slice(0, 11) != timeZoneName) {
                notifyToast(329, "warning");
            }
        }
    }


    const logUserTimezone = () => {
        const offsetMinutes = new Date().getTimezoneOffset();
        const offsetHours = Math.abs(offsetMinutes) / 60;
        const offsetSign = offsetMinutes <= 0 ? '+' : '-';
        const absOffsetHours = String(Math.floor(offsetHours)).padStart(2, '0');
        const absOffsetMinutes = String(Math.abs(offsetMinutes) % 60).padStart(2, '0');
        const formattedOffset = `${offsetSign}${absOffsetHours}:${absOffsetMinutes}`;
        const timezoneName = `(UTC${formattedOffset})`;
        setTimeZoneName(timezoneName);
    }

    const filterBy = () => true;

    useEffect(() => {
        const resourceList = async () => {
            const _resourceList = getCurrentResource(appConst.resource.moduleMenu, "EQ7");
            setCurrentRes({
                isPersonal: _resourceList?.find((list) => list?.code == "PIN") || false,
                isContact: _resourceList?.find((list) => list?.code == "CIN") || false,
                isPreference: _resourceList?.find((list) => list?.code == "PPR") || false
            })
            //console.log(_resourceList);
        };
        resourceList();
    }, [resourceList]);

    //console.log(patientInfo)

    useEffect(() => {
        document.title = 'Update Profile';

        const fetchData = async () => {
            try {
                await getSetting();
                await getprofile();
                await getSettingData();
                await logUserTimezone();
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();


    }, []);


    //console.log(patientMinimumAge)

    return (
        <div className="container-xxl">
            {patientInfo && (
                <>
                    <ScheduleHeader title={getLanguageContent("addprf")} />
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        <>
                            <h5 className="my-4">{currentRes?.isPersonal?.title}</h5>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-6 mb-3">
                                            <label htmlFor="firstName" className="form-label">{getLanguageContent("firstname")}<span className="text-danger star">*</span></label>
                                            <input id="firstName" type="text" autoFocus={true} className="form-control" placeholder={getLanguageContent("entfirstname")} aria-label="First name" {...register("firstName", {
                                                required: getMsg(301),
                                                // pattern: formValidation.alphabet.pattern,
                                                validate: formValidation.validateNoEmptySpaces,
                                                maxLength: { value: 50, message: getMsg("305") }
                                            })} />
                                            {errors?.firstName?.message && <span className="text-danger">{errors?.firstName?.message}</span>}
                                        </div>
                                        <div className="col-lg-6 mb-3">
                                            <label htmlFor="lastName" className="form-label">{getLanguageContent("lastname")}<span className="text-danger star">*</span></label>
                                            <input id="lastName" type="text" className="form-control" placeholder={getLanguageContent("entlastname")} aria-label="Last name" {...register("lastName", {
                                                required: getMsg(301),
                                                //pattern: formValidation.alphabet.pattern,
                                                validate: formValidation.validateNoEmptySpaces,
                                                maxLength: { value: 50, message: getMsg("305") }
                                            })} />
                                            {errors?.lastName?.message && <span className="text-danger">{errors?.lastName?.message}</span>}
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-lg-6 mb-3">
                                            <label htmlFor="genderId" className="form-label">{getLanguageContent("gendr")}<span className="text-danger star">*</span></label>
                                            <select type="text" className="form-control form-select" id="genderId" placeholder={getLanguageContent("gndr")} {...register("genderId", { required: getMsg(301) })}>
                                                <option value="">{getLanguageContent("select")} gender</option>
                                                <option value='1'>Male</option>
                                                <option value='2'>Female</option>
                                                <option value='3'>Other</option>
                                            </select>
                                            {errors?.genderId?.message && <span className="text-danger">{errors?.genderId?.message}</span>}
                                        </div>
                                        <div className="col-lg-6 mb-3">
                                            <label className="form-label" htmlFor="dob">{getLanguageContent("dobh")}<span className="text-danger star">*</span></label>
                                            <Controller
                                                control={control}
                                                name="dob"
                                                rules={{
                                                    required: getMsg(301)
                                                }}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        id='dob'
                                                        name="dob"
                                                        selected={field.value}
                                                        dateFormat={accountDate?.value ? accountDate?.value?.toLowerCase()?.replace(/mm/, 'MM') : 'MM/dd/yyyy'}
                                                        className="form-control"
                                                        onChange={(date) => {
                                                            field.onChange(date)
                                                            setSelectedDate(date);
                                                        }}
                                                        placeholderText={getLanguageContent("entdobh")}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        wrapperClassName="w-100"
                                                        dropdownMode="select"
                                                        maxDate={subYears(new Date(), patientMinimumAge)}
                                                    />
                                                )}
                                            />
                                            {(errors?.dob?.message && !selectedDate) && <span className="text-danger">{errors?.dob?.message}</span>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 mb-3">
                                            <label htmlFor="primaryPhone" className="form-label">{getLanguageContent("prphno")}<span className="text-danger star">*</span></label>
                                            <input type="text" id="primaryPhone" className="form-control" placeholder={getLanguageContent("enprno")} aria-label="Mobile number" {...register("primaryPhone", { required: getMsg(301), pattern: formValidation.number.pattern, maxLength: { value: 15, message: getMsg("305") } })} />
                                            {errors?.primaryPhone?.message && < span className="text-danger">{errors?.primaryPhone?.message}</span>}
                                            <small className="text-muted d-block">{getLanguageContent("srvrld")}</small>
                                        </div>
                                        <div className="col-lg-6 mb-3">
                                            <label htmlFor="ExtRefId" className="form-label">{getLanguageContent("extpid")}</label>
                                            <input type="text" id="ExtRefId" className="form-control" placeholder={getLanguageContent("entpid")} {...register("extRefId", { maxLength: { value: 50, message: getMsg("305") } })} />
                                            {errors.extRefId?.message && <span className="text-danger">{errors.extRefId?.message}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        {(currentRes && currentRes?.isContact) && (
                            <>
                                <h5 className="my-4">{currentRes?.isContact?.title}</h5>
                                <div className="card">
                                    <div className="card-body">

                                        {patientInfo?.parentId && (
                                            <>
                                                <div className="d-flex justify-content-center align-items-center h-100">
                                                    <ActionStatus description={getLanguageContent("fameco")} type="search" />
                                                </div>
                                            </>
                                        )}

                                        {!patientInfo?.parentId && (

                                            <>
                                                <div className="row">
                                                    <div className="col-lg-6 mb-3">
                                                        <label htmlFor="address1" className="form-label">{getLanguageContent("addr")}<span className="text-danger star">*</span></label>
                                                        <input type="text" id="address1" className="form-control" placeholder={getLanguageContent("entaddr")} aria-label="Address 1" {...register("address1", {
                                                            required: getMsg(301),
                                                            //pattern: formValidation.alphabet.pattern,
                                                            validate: formValidation.validateNoEmptySpaces,
                                                            maxLength: { value: 50, message: getMsg("305") }
                                                        })} />
                                                        {errors?.address1?.message && <span className="text-danger">{errors?.address1?.message}</span>}
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <label htmlFor="address2" className="form-label">{getLanguageContent("addrs")}</label>
                                                        <input type="text" id="address2" className="form-control" placeholder={getLanguageContent("entaddrs")} aria-label="Address 2" {...register("address2", {
                                                            maxLength: { value: 50, message: getMsg("305") }
                                                        })} />
                                                        {errors?.address2?.message && <span className="text-danger">{errors?.address2?.message}</span>}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6 mb-3">
                                                        <Controller
                                                            control={control}
                                                            name="locationId"
                                                            rules={{
                                                                required: getMsg(301)
                                                            }}
                                                            render={({ field, fieldState }) => (
                                                                <div className="">
                                                                    <label htmlFor="locationId" className="form-label">{getLanguageContent("ctyst")}<span className="text-danger star">*</span></label>
                                                                    <AsyncTypeahead  {...field}
                                                                        name="locationId"
                                                                        id="locationId"
                                                                        filterBy={filterBy}
                                                                        isLoading={autoCompleteloading}
                                                                        labelKey="combinedLabel"
                                                                        //onSearch={(query) => updateValue(query)}
                                                                        onInputChange={(query) => updateValue(query)}
                                                                        minLength={2}
                                                                        selected={location}
                                                                        onChange={(selected) => {
                                                                            field.onChange(selected)
                                                                            setLocation(selected)
                                                                            if (selected.length === 0) {

                                                                            } else {
                                                                                setValue('locationId', selected[0]?.id);
                                                                            }
                                                                        }}
                                                                        options={addressList}
                                                                        placeholder={getLanguageContent("entctyst")}
                                                                        className={fieldState.invalid ? "is-invalid" : ""}
                                                                        aria-describedby="typeaheadError"
                                                                        renderMenuItemChildren={(option) => (
                                                                            <div>
                                                                                {option?.name}{option?.parent ? <span className='text-muted'>, {option?.parent}</span> : ''}
                                                                            </div>
                                                                        )}
                                                                    />
                                                                    {errors?.locationId?.message && location.length == 0 && <span className="text-danger">{errors?.locationId?.message}</span>}
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <label htmlFor="zipCode" className="form-label">{getLanguageContent("pstcode")}<span className="text-danger star">*</span></label>
                                                        <input type="text" id="zipCode" className="form-control" placeholder={getLanguageContent("entpstcode")} aria-label="Postal code" {...register("zipCode", { pattern: formValidation.number.pattern, required: getMsg(301), maxLength: { value: 10, message: getMsg("305") } })} />
                                                        {errors?.zipCode?.message && <span className="text-danger">{errors?.zipCode?.message}</span>}
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <label htmlFor="secondaryPhone" className="form-label">Secondary phone number</label>
                                                        <input type="text" id="secondaryPhone" className="form-control" placeholder="Enter secondary phone number"  {...register("secondaryPhone", { pattern: formValidation.number.pattern, maxLength: { value: 15, message: getMsg("305") } })} />
                                                        {errors?.secondaryPhone?.message && <span className="text-danger">{errors?.secondaryPhone?.message}</span>}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                        {(currentRes && currentRes?.isPreference) && (
                            <>
                                <h5 className="my-4">{currentRes?.isPreference?.title}</h5>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="col-lg-6 mb-3">
                                            <div>
                                                <label htmlFor="timeZoneId" className="form-label">{getLanguageContent("preftime")}</label>
                                                <select id="timeZoneId" className="form-control form-select"  {...register('timeZoneId', {
                                                    onChange: (e) => handleTimeZone(e)
                                                })}>
                                                    <option value="">{"Select"}</option>
                                                    {setting?.filterDataByCode(timeZones, "TMZ")?.map((value, index) => (
                                                        <option value={value?.settingOptionId} key={index}>
                                                            {value?.displayText}
                                                        </option>
                                                    ))}
                                                </select>
                                                <small className="text-muted">{getLanguageContent("slcttimezone")}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="row mt-4">
                            <div className="col text-end">
                                <button type="submit" className="btn btn-primary btn-lg">{getLanguageContent("prceed")}</button>
                            </div>
                        </div>
                    </form>
                </>
            )}
        </div>
    );
}

export default AddProfile;
