import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { serverGet } from "../../../middleware/http";
import { apiConst } from "../../../services/constant/constant";
import ProgressContext from "../../../services/utils/progress";
import { ecnSession, getSearchParams, isEnabled } from "../../../services/utils/validation";
import DoctorSummary from "../consultation/summary/DoctorSummary";
import ScheduleHeader from "../consultation/summary/ScheduleHeader";
import NotifyContext from "../../../services/utils/notify";
import { getEncounterMode } from "../consultation/summary/ScheduleConfirmation";
import { useLanguageRes } from '../../../services/Context/LanguageContext';
import { useAccountSettings } from "../../../services/Context/AccSettingContext";

export default function Payment() {
    const { register, handleSubmit, setValue } = useForm();

    const navigate = useNavigate();

    //const [selectedPay, setSelectedPay] = useState("");
    const [payMethods, setPayMethods] = useState([]);
    const [pricing, setPricing] = useState(null);
    const { progParams } = useContext(ProgressContext);
    const { notifyToast } = useContext(NotifyContext);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const encounterModeId = getSearchParams("emid");

    const { getLanguageContent } = useLanguageRes();
    const { accountSettings } = useAccountSettings();


    //const submitOnline = () => {
    //    serverGet(`${apiConst.scheduleupdate}?scheduleId=${getSearchParams("schid")}&paymethodType=${1}`).then((res) => {
    //        try {
    //            if (res?.status == 1) {
    //                sessionStorage.removeItem('redirectUrl');
    //                navigate(`/confirmation?eid=${res?.data?.scheduleId}`);
    //            } else {
    //                var error = JSON.parse(res?.data?.errorMessage);
    //                notifyToast(error?.messageCode[0]);
    //            }
    //        } catch (e) {
    //            console.log(e);
    //        }
    //    })
    //}

    const onSubmit = async (data) => {
        // const sessionStore = ecnSession();
        if (!data?.mode) return notifyToast(342);

        const pay_method = payMethods.find(item => item.settingOptionId == data?.mode)?.code
        const schedule_id = getSearchParams("schid");
        const encounter_id = getSearchParams("eid");
        const durationId = getSearchParams("did");

        ecnSession(schedule_id, "encid");
        ecnSession(encounter_id, "encounterId");
        const app_date = urlSearchParams.get('ad');
        const time_slot = urlSearchParams.get('ts');
        const duration_name = urlSearchParams.get('dn');
        const enc_mode = urlSearchParams.get('m');
        const enc_reason = urlSearchParams.get('r');
        const enc_id = urlSearchParams.get('eid');
        const enc_pharmacy = urlSearchParams.get('p');
        const minimum_price = getSearchParams("minP");
        const provider_memberId = getSearchParams("memberid");
        const fmid = getSearchParams("fmid");
        const _brtype = getSearchParams("brtype");

        if (pay_method == "INS") {
            navigate(`/payment/insurance?memberid=${provider_memberId}&ad=${app_date}&schid=${schedule_id}&minP=${minimum_price}&emid=${encounterModeId}&did=${durationId}&ts=${encodeURIComponent(time_slot)}&m=${enc_mode}&dn=${duration_name}&r=${enc_reason}&eid=${enc_id}&p=${encodeURIComponent(enc_pharmacy)}&spid=${getSearchParams("spid")}${fmid ? `&fmid=${fmid}` : ""}&brtype=${_brtype}`);
        } else if (pay_method == "IPPAY") {

            try {
                progParams(true);
                const res = await serverGet(`${apiConst.scheduleupdate}?scheduleId=${schedule_id}&paymethodType=${3}`);

                if (res?.status == 1) {
                    sessionStorage.removeItem('redirectUrl');
                    //    navigate(`/confirmation?eid=${schedule_id}`);
                    return navigate(`/processPayment?eid=${schedule_id}`);
                } else {
                    var error = JSON.parse(res?.data?.errorMessage);
                    notifyToast(error?.messageCode[0]);
                }
            } catch (e) {
                console.log(e);
            } finally {
                progParams(false);
            }
        } else if (pay_method == "OPAY") {
            navigate(`/paymentprocess?eid=${schedule_id}${fmid ? `&fmid=${fmid}` : ""}`);
        }
    }


    const getAccountSettings = async () => {
        try {
            progParams(true);
            const encounterModeId = getSearchParams("emid");
            //console.log(encounterModeId)
            let minimum_price = getSearchParams("minP");
            minimum_price = (parseFloat(minimum_price) > 0) ? true : false;
            setPricing(minimum_price);
            const settingsCode = "PMTD,PFS";
            const getsetting = await serverGet(`${apiConst.getaccountsettings}/-/${encodeURIComponent(settingsCode)}`);
            if (((Array.isArray(getsetting?.data) && isEnabled(getsetting?.data, "PFS", 0)) || (!minimum_price))) {
                progParams(true);
                const res = await serverGet(`${apiConst.scheduleupdate}?scheduleId=${getSearchParams("schid")}&paymethodType=${1}&skipPayment=true`);
                progParams(false);
                if (res?.status == 1) {
                    sessionStorage.removeItem('redirectUrl');
                    //return navigate(`/confirmation?eid=${res?.data?.scheduleId}`);
                    return navigate(`/processPayment?eid=${res?.data?.scheduleId}`);
                } else {
                    var error = JSON.parse(res?.data?.errorMessage);
                    return notifyToast(error?.messageCode[0]);
                }
            }
            const getvalue = await serverGet(apiConst.getsettingoptions + "/" + settingsCode);
            if (Array.isArray(getsetting?.data) && Array.isArray(getvalue?.data) && isEnabled(getsetting?.data, "PFS", 1)) {
                if (getsetting?.data?.length > 0 && getvalue?.data?.length > 0) {
                    const filteredResult = getsetting?.data?.filter((list) => list.settingCode == "PMTD");
                    const settingvalue = filteredResult[0]?.value?.split(",");
                    const settingId = filteredResult[0]?.settingOptionId;
                    if (settingvalue?.length > 0) {
                        const result = getvalue?.data?.filter((item) => settingvalue.includes(item?.settingOptionId) || (item?.settingOptionId == settingId));
                        if (result?.length > 0) {
                            //const list_result = result?.filter((list) => getEncounterMode(encounterModeId) == "Online" || getEncounterMode(encounterModeId) == "Phone" ? list.code !== "IPPAY" : true);
                            const list_result = result?.filter((list) => {
                                if (getEncounterMode(encounterModeId) == "In Person") {
                                    return true;
                                } else {
                                    return list.code !== "IPPAY"
                                }
                            });
                            setPayMethods(list_result);
                        }
                    }
                }
            }
        } catch (e) {
            console.log(e);
        } finally {
            progParams(false);
        }
    };

    //console.log(getEncounterMode(encounterModeId))


    useEffect(() => {
        document.title = "Payment";
        const fetchData = async () => {
            try {
                await getAccountSettings();

            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);


    useEffect(() => {
        if (payMethods?.length == 1) {
            setValue("mode", payMethods[0]?.settingOptionId);
            //console.log(payMethods);
        }
    }, [payMethods]);



    //console.log(payMethods);
    return (
        <>

            {(isEnabled(accountSettings, "PFS", 1) && pricing) && (
                <>
                    <ScheduleHeader title={getLanguageContent("pymnt")} />
                    <div className="d-flex flex-md-row flex-column">
                        <DoctorSummary />
                        <div className="flex-grow-1">
                            <div className="card border-0">
                                <div className="card-body p-2 p-md-4">
                                    {/*<h4>Coupon</h4>*/}
                                    {/*<div className="row">*/}
                                    {/*    <div className="col-lg-8">*/}
                                    {/*        <div className="mb-3 me-3">*/}
                                    {/*            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Enter coupon code" />*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="col-lg-4">*/}
                                    {/*        <div className="mb-3">*/}
                                    {/*            <button type="button" className="btn btn-primary">Apply</button>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <h5>{getLanguageContent("chsprfrdmthd")}</h5>
                                    {/*<div className="d-flex justify-content-between my-3">
                                <div>
                                    <h4 className="mb-2">{getLanguageContent("pybleamt")}</h4>
                                    <p className="mb-2">{getEncounterMode(sessionStore?.eid)} - <span className="fw-bold">{getConsultationMode(sessionStore?.did)} min</span></p>
                                    <p className="mb-2 text-secondary">{reason}</p>
                                </div>
                                {AccountCurrency?.value && sessionStore?.minP ?
                                    <div>
                                        <h3>{AccountCurrency?.value}{sessionStore?.minP?.toFixed(2)}</h3>
                                    </div>
                                    : ""
                                }
                            </div>*/}
                                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                        <div className="mt-2">
                                            {(payMethods?.length > 0 && isEnabled(accountSettings, "PFS", 1)) && payMethods?.map((list, i) => (
                                                <div className="card mb-4">
                                                    <div className="card-body p-2 p-md-4 d-flex align-items-center">
                                                        <div className="form-check" key={i}>
                                                            <input className="form-check-input" type="radio" value={list?.settingOptionId} id={list?.settingOptionId} {...register("mode", {
                                                                //required: getMsg(301),
                                                                //onChange: (e) => setSelectedPay(e?.target?.value)
                                                            })} />
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <label className="form-check-label d-block fw-semibold" htmlFor={list?.settingOptionId}>
                                                                {list?.displayText}
                                                                {list?.description ? <>
                                                                    <br />
                                                                    <span className="text-secondary fw-normal">{list?.description}</span>
                                                                </> : ""}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            {/*    {errors?.mode?.message && <p className="text-danger">{errors?.mode?.message}</p>}*/}
                                        </div>
                                        {isEnabled(accountSettings, "PFS", 1) && (
                                            <>
                                                <hr className="my-4 d-none d-sm-block" />
                                                <div className="my-4 text-end">
                                                    <button type="submit" className="btn btn-lg btn-primary">{getLanguageContent("prced")}</button>
                                                </div>
                                            </>
                                        )}

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </>)}
        </>
    );
}

