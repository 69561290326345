import React from "react";
import { useNavigate } from 'react-router-dom';
import { useLanguageRes } from "../../../services/Context/LanguageContext";


const MemberPlanWidget = ({ arr, code }) => {

    const { getLanguageContent } = useLanguageRes();
    const navigate = useNavigate();

    const getTitle = (name) => {
        if (name == "Behavioral Health")
            return getLanguageContent("phsebev")
        else if (name == "Care Coordination")
            return getLanguageContent("phsecar")
        else if (name == "Medical Specialist")
            return getLanguageContent("phsemes")
    }


    const getDesc = (name) => {
        if (name == "Behavioral Health")
            return getLanguageContent("phsebeh")
        else if (name == "Care Coordination")
            return getLanguageContent("phsecco")
        else if (name == "Medical Specialist")
            return getLanguageContent("phsemed")
    }

    const navigateDetailPage = (name) => {
        const _content = name == "Behavioral Health" ? "phseblm" : name == "Care Coordination" ? "phseclm" : name == "Medical Specialist" ? "phsemlm" : ""
        const _title = name == "Behavioral Health" ? "phsebev" : name == "Care Coordination" ? "phsecar" : name == "Medical Specialist" ? "phsemes" : ""

        return navigate(`/plan/detail?p=${code}&t=${_title}&c=${_content}`)
    }


    return (
        <>
            {arr?.map((list, index) => {
                return (
                    <>
                        {list?.code?.includes(code) && (
                            <div className="col d-flex align-items-stretch g-4" key={index}>
                                <div className="card border-0 p-2 w-100">

                                    <div className="card-body pb-0">
                                        <h4>{getTitle(list?.name)}</h4>
                                        <div dangerouslySetInnerHTML={{ __html: getDesc(list?.name) }}></div>
                                    </div>
                                    <div className="card-footer bg-white border-0 pt-0 pb-3">
                                        <button type="button" onClick={() => navigateDetailPage(list?.name)} className="btn btn-primary btn-lg">{getLanguageContent("phsemor")}</button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )
            })}
        </>
    )
}

export default MemberPlanWidget



//    < Link className = "text-white" to = "/plan/detail" state = {{
//    planName: getTitle(list?.name),
//        content: list?.name == "Behavioral Health" ? "phseblm" : list?.name == "Care Coordination" ? "phseclm" : list?.name == "Medical Specialist" ? "phsemlm" : ""
//}}></Link >