import React, { useState, useEffect, useContext } from "react";
import InsuranceForm from "./insForm";
import InsuranceList from "./List";
import { useLocation, useNavigate } from 'react-router-dom';
//import { getConsultationMode, getEncounterMode } from "../consultation/summary/ScheduleConfirmation";
import { useAccountSettings } from "../../../services/Context/AccSettingContext";
import { ecnSession, getSearchParams } from "../../../services/utils/validation";
import { apiConst, resourceConst } from "../../../services/constant/constant";
import { serverGet } from "../../../middleware/http";
import ProgressContext from "../../../services/utils/progress";
import NotifyContext from "../../../services/utils/notify";
import ScheduleHeader from "../consultation/summary/ScheduleHeader";
import { useLanguageRes } from '../../../services/Context/LanguageContext';
import { useManageResource } from "../../../services/Context/ManageResourceContext";


export default function Insurance({ insuranceFlow, code, level }) {

    const [insuranceId, setInsuranceId] = useState(null);
    const [showIns, setShowIns] = useState(false);
    //const [insuranceFlow, setInsuranceFlow] = useState(false);
    const [insuranceList, setInsuranceList] = useState([]);

    const [resourceAction, setResourceAction] = useState([]);

    const location = useLocation();
    const { accountDate, AccountCurrency, showAccPricing, cateogrySettings } = useAccountSettings();
    const { resourceList, getCurrentResourceAction, actionExists } = useManageResource();

    const { progParams } = useContext(ProgressContext);
    const { notifyToast } = useContext(NotifyContext);

    const navigate = useNavigate();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const reason = urlSearchParams.get('r');
    const schedule_id = getSearchParams("schid");
    const durationId = getSearchParams("did");
    const encounterModeId = getSearchParams("emid");
    const minimum_Price = getSearchParams("minP");
    const _brtype = getSearchParams("brtype");


    //const sessionStore = ecnSession();
    const { getLanguageContent } = useLanguageRes();

    const toggleIns = () => {
        setShowIns(!showIns);
    }


    const submitInsurance = async () => {
        if (insuranceId) {
            try {
                progParams(true);
                const res = await serverGet(`${apiConst.scheduleupdate}?scheduleId=${schedule_id}&paymethodType=${2}&memberInsuranceId=${insuranceId}`);
                progParams(false);
                if (res?.status == 1) {
                    sessionStorage.removeItem('redirectUrl');
                    return navigate(`/processPayment?eid=${schedule_id}`);
                } else {
                    var error = JSON.parse(res?.data?.errorMessage);
                    notifyToast(error?.messageCode[0]);
                }
            } catch (e) {
                console.log(e);
            }
        } else {
            notifyToast(340);
        }
    }


    useEffect(() => {
        if (code) {
            const _action = getCurrentResourceAction(code)
            setResourceAction(_action);
        }
    }, [resourceList])


    // console.log(resourceAction);

    useEffect(() => {

        //document.title = "Insurance payment";

        if (insuranceFlow) {
            //setInsuranceFlow(true);
            document.title = "Insurance payment";
        } else {
            document.title = "Insurance";
        }
    }, [])

    return (
        <>
            {insuranceFlow && (
                <ScheduleHeader title={getLanguageContent("insrncepymt")} />
            )}
            {insuranceFlow &&
                <div className="card border-0 mb-3">
                    <div className="card-body">
                        {(AccountCurrency?.value && minimum_Price && parseFloat(minimum_Price) > 0 && showAccPricing) && (
                            <h5>{getLanguageContent("cmpltpymnt")}</h5>
                        )}
                        <div className="d-flex justify-content-between my-3">
                            <div>
                                {(AccountCurrency?.value && minimum_Price && parseFloat(minimum_Price) > 0 && showAccPricing) && (
                                    <h4 className="mb-2">{getLanguageContent("pybleamt")}</h4>
                                )}
                                <p className="mb-2">{cateogrySettings?.find((list) => list?.id == encounterModeId && list?.group == "encounter_mode")?.name}{_brtype != "GT6" && <span className="fw-bold"> - {cateogrySettings?.find((list) => list?.id == durationId && list?.group == "schedule_duration")?.name}</span>}</p>
                                <p className="mb-2 text-secondary">{reason}</p>
                            </div>
                            {(AccountCurrency?.value && minimum_Price && parseFloat(minimum_Price) > 0 && showAccPricing) && (
                                <div>
                                    <h3>{AccountCurrency?.value}{parseFloat(minimum_Price)?.toFixed(2)}</h3>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            }
            <div className={insuranceFlow ? "card border-0" : ""}>
                <div className={insuranceFlow ? "card-body" : ""}>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <div>
                            <h4 className={`${insuranceFlow ? "fs-5" : ""} m-0`}>{showIns && insuranceId ? getLanguageContent("edtinsrnce") : showIns ? getLanguageContent("addinsrnce") : getLanguageContent("insrnce")}</h4>
                        </div>
                        {!showIns ?
                            <div>
                                {insuranceFlow || (!insuranceFlow && actionExists(resourceAction, resourceConst?.actions?.create)) ? (
                                    <button onClick={toggleIns} className="btn btn-primary">{getLanguageContent("addnw")}</button>
                                ) : ""}
                            </div>
                            : ""}
                    </div>
                    <div className={insuranceFlow ? "" : "card border-0"}>
                        <div className={insuranceFlow ? "" : "card-body"}>
                            {!showIns && <InsuranceList setInsuranceId={setInsuranceId} insuranceFlow={insuranceFlow} toggleIns={toggleIns} setInsuranceList={setInsuranceList} />}

                            {showIns && <InsuranceForm insuranceId={insuranceId} insuranceFlow={insuranceFlow} toggleIns={toggleIns} setInsuranceId={setInsuranceId} isEdit={actionExists(resourceAction, resourceConst?.actions?.edit)} isCreate={actionExists(resourceAction, resourceConst?.actions?.create)} />}

                            {(insuranceFlow && !showIns && Array.isArray(insuranceList) && insuranceList.filter((list) => list?.status == "Active" && list?.verificationStatusId != "4")?.length > 0) ?
                                <div className="text-end">
                                    <button onClick={submitInsurance} className="btn btn-primary btn-lg">{getLanguageContent("prced")}</button>
                                </div> : ""
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


