import React, { useState, Suspense, useEffect } from 'react';


const Tab = (props) => {

    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const urlSearchParams = new URLSearchParams(window.location.search);

    const [dropdown, setDropdown] = useState({ state: window.innerWidth <= 991, class: "" });

    const setWindowDimensions1 = () => {
        const mobilestate = window.innerWidth <= 991
        setDropdown({ state: mobilestate, class: "" });
    }

    useEffect(() => {
        window.addEventListener('resize', setWindowDimensions1);
        const tab_name = urlSearchParams.get("tn");


        props.tabs?.map((list, i) => {
            //if (list.name == 'Form' && list?.show) {
            //    const newUrl = new URL(window.location);
            //    newUrl.searchParams.set("tn", list.name);
            //    window.history.replaceState(null, '', newUrl);
            //    setCurrentTabIndex(parseInt(i));
            //    list.show = false
            //} else if (list?.show) {
            //    const newUrl = new URL(window.location);
            //    newUrl.searchParams.set("tn", list.name);
            //    window.history.replaceState(null, '', newUrl);
            //    setCurrentTabIndex(parseInt(i));
            //    list.show = false
            //} else

            if (tab_name == list.name && tab_name !== "" && tab_name) {
                //console.log(i);
                setCurrentTabIndex(parseInt(i));
            }
        })
    }, [props])


    const tabClick = (index, tab) => {
        //console.log(name);
        const newUrl = new URL(window.location);
        newUrl.searchParams.set("tn", tab?.name);

        if (tab?.isForm) {
            newUrl.searchParams.set("frmid", tab?.id);
        }
        window.history.replaceState(null, '', newUrl);

        setDropdown({ state: dropdown.state, class: index == currentTabIndex && dropdown.state ? "active" : "" });
        setCurrentTabIndex(index);

    }

    //console.log(props)
    const renderTabPanel = () => {

        if (props.tabs.length != 0) {
            const TabPanel = props.tabs[currentTabIndex].component;
            return <Suspense fallback={<div>Loading...</div>}>
                <TabPanel
                    data={props.tabs[currentTabIndex]?.data}
                    currentabData={props.tabs[currentTabIndex]}
                    encounter={props?.encounter}
                    accountDate={props?.accountDate}
                    patient={props?.patient}
                    selectedFormId={props.tabs[currentTabIndex].id}

                />
            </Suspense>;
        }
    };




    return (
        <>
            <div className="d-flex flex-lg-row flex-column">
                {
                    <div className="card border-0 mb-3 me-lg-3 flex-shrink-0">
                        <div className="card-body content-left-tab">
                            {props.title != undefined ? <h4 className="card-title fw-bold ">{props?.title}</h4> : <></>}
                            <ul role="tablist" className="position-relative responsive-tab m-0">

                                {props.tabs.map((tab, index) => (
                                    tab.display == null || tab.display == 1 ?
                                        <li key={`tab${index}`} className={index === currentTabIndex ? "active " + (dropdown.class == "" ? "tab-border" : "") : dropdown.class}>
                                            <a className='pointer-class text-decoration-none ' key={index} role="tab" onClick={() => tabClick(index, tab)} aria-selected={index === currentTabIndex} aria-controls={'tab-panel-${index}'}>
                                                {tab?.name}

                                                {
                                                    (index === currentTabIndex) ? <svg className="position-absolute end-0" width="7" height="12" role="img"><use xlinkHref="#svg_tabactive" /></svg> : ""
                                                }
                                            </a>
                                        </li> : <></>
                                ))}
                            </ul>
                        </div>
                    </div>
                    //        :
                    //        <></>
                }
                <div className="card border-0 mb-3 flex-grow-1">
                    <div className="card-body">
                        {renderTabPanel()}
                    </div>
                </div>
            </div>
        </>
    );
}
export default Tab
