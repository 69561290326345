import React, { useContext, useEffect, useState, useSearchParams } from "react"
import { useForm } from 'react-hook-form';
import { Link, useLocation } from "react-router-dom";
import { apiConst, appConst } from '../../../services/constant/constant'
import { serverGet, serverPost, serverFormPost, serverAPIPost } from '../../../middleware/http'
import { formValidation, getAuthProfile, getMsg, getSearchParams, isEnabled } from '../../../services/utils/validation';
import NotifyContext from "../../../services/utils/notify";
import ProgressContext from "../../../services/utils/progress";
import { PasswordField } from "../../../services/utils/forms";
import { useLanguageRes } from '../../../services/Context/LanguageContext';
import { useAccountSettings } from "../../../services/Context/AccSettingContext";
import { useProfile } from "../../../services/utils/profileContext";
import { JsonToURL } from "../security/SignUp";


const Login = () => {

    const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm();
    const { checkUserProfile, getFamilyList } = useProfile();
    //const [persist, setPersist] = useState(JSON.parse(localStorage.getItem("persist")) || false);
    const { notifyToast } = useContext(NotifyContext);
    const { getLanguageContent } = useLanguageRes();
    const redirectUrl = sessionStorage.getItem("redirectUrl");
    const authenticationUrl = sessionStorage.getItem("redirectAuthentication");
    const location = useLocation();
    //const navigate = useNavigate();
    const encounter_id = getSearchParams("eid");
    const bookNow_click = getSearchParams("c"); //y == yes else null or undefined
    const visit_detail_page = getSearchParams("isrp"); // visit detail page navigate back to reason page to complete apointment t == yes else null or undefined
    const residingId = getSearchParams("rsid") || location?.state?.id || "";
    const { accountSettings } = useAccountSettings();
    const { progParams } = useContext(ProgressContext);

    const searchParams = new URLSearchParams(window.location.search);
    
    const [ClientId, setClientId] = useState(searchParams.get("client_id"));//useState(searchParams?.client_id);
    const [ClientSecret, setClientSecret] = useState(searchParams.get("client_secret"));//useState(searchParams?.client_secret);
    const [Redirect_uri, setRedirect_uri] = useState(searchParams.get("redirect_uri"));//useState(searchParams?.redirect_uri);
    const [To_redirect_uri, setTo_redirect_uri] = useState(searchParams.get("to_redirect_uri")); //useState(searchParams?.to_redirect_uri);

    useEffect(() => {
        if(ClientId!==null)
            setClientCredentials();
    }, [ClientId]);

    const setClientCredentials = () =>
    {
        let sso_strgeObj = sessionStorage.getItem("sso_redirect_uri");
        if (sso_strgeObj != null && typeof sso_strgeObj !== "undefined") {

            sso_strgeObj = JSON.parse(sso_strgeObj);
            
            setClientId(sso_strgeObj.client_id);
            setClientSecret(sso_strgeObj.client_secret);
            setRedirect_uri(sso_strgeObj.redirect_uri);
            //ssoQueryParams = JsonToURL(JSON.parse(sso_strgeObj));
           
        } else {
           
            sessionStorage.setItem("sso_redirect_uri", JSON.stringify({
                client_id: ClientId,
                client_secret: ClientSecret,
                redirect_uri: Redirect_uri,
                to_redirect_uri: To_redirect_uri
            }));
        }
    }

    useEffect(() => {
        document.title = 'Sign in'; 
    }, []);

    const handleSSORedirect = (IsFrgtPwd) => {
        if (ClientId !== "" && ClientId !== null) {
            let sso_redirect_uri = { response_type : getSearchParams("response_type"),
                scope: getSearchParams("scope"),
                client_secret: ClientSecret,
                client_id: ClientId,
                state: getSearchParams("state"),
                redirect_uri: Redirect_uri,
                to_redirect_uri: To_redirect_uri
            }
            
            if (sessionStorage.getItem("sso_redirect_uri") === null) {
                sessionStorage.setItem("sso_redirect_uri", JSON.stringify(sso_redirect_uri));
                IsFrgtPwd = true;
            }
        }
        return window.location.href = IsFrgtPwd ? `/security/forgotpassword?client_redirect=true` :`/security/signup?client_redirect=true`;

    }

    //console.log(residingId);
    //   console.log(bookNow_click)
    const onSubmit = async (data) => {
       
        try {
            const formData = {
                username: data?.Username?.trim(),
                password: data?.Password            
            }
            if (ClientId !== null && ClientId !== "" && typeof ClientId != "undefined")
            {

                progParams(true);
                var ssoFrmData = new FormData();
                ssoFrmData.append("client_id", ClientId);
                ssoFrmData.append("client_secret", ClientSecret);
                ssoFrmData.append("grant_type", "password");
                ssoFrmData.append("username", formData.username);
                ssoFrmData.append("password", formData.password);
            
                try {
                    (async () => {
                        await serverFormPost(`${apiConst.ssologin}`, ssoFrmData, false).then(res => {
                            try {
                                if (res?.access_token) {
                                    (async () => {
                                        //console.log(res.id_token);
                                        
                             
                                        await serverAPIPost(`${apiConst.authorizelogin}`, { "Value": res.access_token }).then(
                                            resdata  => {
                                                if (typeof resdata == "boolean") {
                                                    if (resdata)
                                                    {
                                                        progParams(true);
                                                        return window.location.href = `${Redirect_uri}&code=${res.access_token}&state=b3BlbmlkY29ubmVjdA%3D%3D&to_redirect_uri=${To_redirect_uri}`;
                                                    } else 
                                                        notifyToast(311); 
                                                   
                                                } else {
                                                   notifyToast(224); 
                                                }
                                            }).catch(err => {
                                                console.log(err);
                                                notifyToast(224); 
                                            });
                                     
                                    })();
                                } else { progParams(false);  notifyToast(311);  }
                            } catch (err) { progParams(false); console.log(err) }
                        });
                    })();
                } catch (err) { progParams(false); console.log(err); }
            } else {
                progParams(true);
                const res = await serverPost(`${apiConst.postlogin}`, formData);
                if (res === true)
                {
                    getAuthProfile.saveProfileAuth();
                    const _isProfile = await checkUserProfile();
                    if (encounter_id && encounter_id !== "" && visit_detail_page) {
                        try {
                            // get profile                       
                           // const profile_res = await serverGet(apiConst.getuserprofile);
                            const res_enc = await serverGet(apiConst.getencounter + encounter_id);
                           // const res_profile = JSON.parse(profile_res?.data?.profile);
                            if (!_isProfile?.pofileCompleted) {
                                return window.location.href = `/addprofile${encounterUrl(res_enc?.data)}`;;
                            }
                            else if (isEnabled(accountSettings, "CPI", 1)) {
                                //const document_Res = await serverGet(`${apiConst.doclist}${res_profile?.data?.id}?recordid=${res_profile?.data?.id}&gn=${appConst.cateogries.proofUpload}`); //to cehck id verification document upload length
                                if (_isProfile?.idVerification) {
                                    //call encounter
                                    if (res_enc?.data?.statusId == 5)
                                        return window.location.href = `/reason${encounterUrl(res_enc?.data)}`;
                                    else
                                        return window.location.href = "/dashboard";
                                } else {
                                    return window.location.href = `/idverification/upload${encounterUrl(res_enc?.data)}`;
                                }
                            } else {
                                if (res_enc?.data?.statusId == 5)
                                    return window.location.href = `/reason${encounterUrl(res_enc?.data)}`;
                                else
                                    return window.location.href = "/dashboard";
                            }
                        } catch (e) {
                            console.error(e);
                        }
                    } else if (redirectUrl && redirectUrl !== '' && bookNow_click && bookNow_click == "y") {
                        try {
                            const url = JSON.parse(redirectUrl);
                            const _isFamily = await getFamilyList();

                            const profileUrl = `/addprofile${residingId ? `?rsid=${residingId}` : ""}`;
                            if (!_isProfile?.pofileCompleted) {
                                window.history.replaceState(null, '', "/addprofile");
                                return window.location.href = profileUrl;
                            }
                            if (_isFamily) {
                                url.path = url?.familyMemberUrl;
                                sessionStorage.setItem("redirectUrl", JSON.stringify(url));
                            }
                            window.history.replaceState(null, '', url.path);
                            return window.location.href = url.path;
                        } catch (e) {
                            console.error(e);
                        }
                    } else if (authenticationUrl && authenticationUrl?.trim() !== "") {
                        const url = JSON.parse(authenticationUrl);
                        sessionStorage.removeItem("redirectAuthentication");
                        if (url?.includes("/security")) {
                            return window.location.href = "/dashboard";
                        }
                        //window.history.replaceState(null, '', url);

                        return window.location.href = url;
                    } else {
                        window.history.replaceState(null, '', "/dashboard");
                        return window.location.href = "/dashboard";
                    }
            } else {
                const error = JSON.parse(res.data.errorMessage);
                    notifyToast(error.messageCode[0]); progParams(false);
                }
            }
        } catch (error) {
            console.error(error); progParams(false);
        } finally {
         //progParams(false);
        }
    };



    function encounterUrl(res_enc) {
        const memberid = res_enc?.providerId;
        const did = res_enc?.durationId;
        const emid = res_enc?.modeId;
        const ts = res_enc?.scheduleOn;
        const spid = res_enc?.specialityId;
        const rsid = residingId;
        const rnote = res_enc?.note;
        const rid = res_enc?.reasonId;
        const r = res_enc?.reason;
        const scheId = res_enc?.scheduleId;
        const statusId = res_enc?.statusId;
        const eid = getSearchParams("eid");
        return `?memberid=${memberid}&did=${did}&encstatusid=${statusId}&emid=${emid}&ts=${ts}&spid=${spid}&rnote=${rnote}&rid=${rid}&r=${r}&eid=${eid}&scheId=${scheId}${rsid ? `&rsid=${rsid}` : ""}${res_enc?.statusId == 5 ? "&isrp=t" : ""}${res_enc?.serviceOptionId ? `&speicalityservice=${res_enc?.serviceOptionId}` : ""}`;

    }



    //useEffect(() => {
    //    const preventBack = (event) => {
    //        event.preventDefault();
    //        event.returnValue = '';
    //    };

    //    window.history.pushState(null, null, window.location.pathname);
    //    window.addEventListener('popstate', preventBack);

    //    return () => window.removeEventListener('popstate', preventBack);
    //}, []);


    


    //useEffect(() => {
    //    const _logout = getSearchParams("lt")

    //    const handleBackButton = () => {
    //        window.history.pushState({ name: "browserBack" }, "on browser back click", window.location.href);
    //        window.history.pushState({ name: "browserBack" }, "on browser back click", window.location.href);
    //        window.history.forward();
    //    };

    


    //        handleBackButton();

    //        // Add event listener for browser back button
    //        window.addEventListener('popstate', (event) => {
    //            if (event.state && event.state.name === "browserBack") {
    //                // do your code here
    //                window.history.forward();
    //                console.log("Browser back button clicked");
    //            }
    //        });
        
    //    return () => {
          
    //            window.onunload = null;
    //            window.removeEventListener('popstate', handleBackButton);
    //    };
    //}, []);

    //const togglePersist = () => {
    //    setPersist(prev => !prev);
    //}

    //useEffect(() => {
    //    localStorage.setItem("persist", persist);
    //}, [persist])


    //useEffect(() => {
    //    const getAuthCookies = async () => {
    //        try {
    //            const res = await serverGet(apiConst.authcookies);
    //            if (res?.status == 1) {
    //                setValue('Username', res?.data?.payload?.u);
    //                setValue('Password', res?.data?.payload?.w);
    //            }                
    //        } catch (error) {
    //            console.error(error);
    //        }
    //    }

    //    if (persist)
    //    getAuthCookies();
    //}, []);

    return (
        <>
            <h6 className="card-title text-uppercase fw-bold mt-3">{getLanguageContent("sgnin")}</h6>
            <form onSubmit={handleSubmit(onSubmit)} method="post" autoComplete="off">
                <div className="my-4">
                    <label htmlFor="txtUsername" className="form-label">{getLanguageContent("email")}<span className="text-danger">*</span></label>
                    <input type="text" autoFocus={true} className="form-control body-secondary-color" id="txtUsername" placeholder={getLanguageContent("entemail")}  {...register("Username", { required: getMsg(301), pattern: formValidation.email.pattern })} />
                    {errors.Username?.message && <p className="text-danger">{errors.Username?.message}</p>}
                </div>
                <div className="mb-4">
                    <PasswordField
                        name="Password"
                        label={getLanguageContent("psswrd")}
                        errors={errors}
                        register={register}
                        icon={true}
                        placeholder={getLanguageContent("entpsswrd")}
                        validationSchema={{
                            required: getMsg(301),
                            maxLength: {
                                value: 50,
                                message: getMsg("305")
                            }
                            //pattern: formValidation.invalidPassword.pattern,
                        }}
                    />
                </div>
                <div className="mb-4 d-flex justify-content-between">
                    {/*<div className="">*/}
                    {/*    <input onChange={togglePersist}*/}
                    {/*        checked={persist} type="checkbox" className="form-check-input me-1" id="cbRememberMe" />*/}
                    {/*    <label className="form-check-label" htmlFor="cbRememberMe">Remember me</label>*/}
                    {/*</div>*/}
                    <div className="">{(ClientId === "" || ClientId === null || typeof ClientId === "undefined") ? <Link to="/security/forgotpassword" className="text-decoration-underline">{getLanguageContent("fgtpsswrd")}</Link> :
                        <a className="link text-decoration-underline" onClick={() => handleSSORedirect(true)}>{getLanguageContent("fgtpsswrd")}</a>}
                        </div>
                </div>
                <div className="mb-3">
                    <button className="btn btn-primary  mb-2">{getLanguageContent("signin")}</button>

                    {(isEnabled(accountSettings, "DCS", 0)) && (
                        <div className="col">{getLanguageContent("account")} {(ClientId === "" || ClientId === null || typeof ClientId === "undefined") ? <Link to={`/security/signup${bookNow_click == "y" ? "?c=y" : ""}`}
                            state={{
                                id: residingId ? residingId : ""
                            }}
                        >{getLanguageContent("signup")}</Link> : <a className="link" onClick={() => handleSSORedirect(false)}>{getLanguageContent("signup")}</a>} {getLanguageContent("here")}
                        </div>
                    )}
                 
                </div>
            </form>

        </> 
    );
}

export default Login;

                        {/*{sso_redirect_uri == "" ?  <Link 
                            state={{
                                id: residingId ? residingId : ""
                            }}
                        >{getLanguageContent("signup")}</Link> } */}

export const GetClientId = () => {

}