import React from "react";
import Forms from "../forms";
import { appConst } from "../../../../../services/constant/constant";

export default function PatientForms() {
    return (
        <>
            <div>
                <Forms level="patient-forms" areaId={appConst.areaId.patientLevelForms } />
            </div>
        </>
    )
}