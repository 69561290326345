import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ecnSession, getAuthProfile, getSearchParams, isEnabled } from "../../../services/utils/validation";
import { useAccountSettings } from '../../../services/Context/AccSettingContext';
import { useProfile } from '../../../services/utils/profileContext';
import ProgressContext from '../../../services/utils/progress';
import { serverGet } from '../../../middleware/http';
import { apiConst, resourceConst } from '../../../services/constant/constant';
import { useManageResource } from '../../../services/Context/ManageResourceContext';

export default function AppointmentMode() {

    const navigate = useNavigate();
    const { getAccountSetting } = useAccountSettings();
    const { patientInfo, checkUserProfile, getFamilyList } = useProfile();
    const { progParams } = useContext(ProgressContext);
    const { getCurrentResourceAction, actionExists } = useManageResource();




    useEffect(() => {
        const appointmentCode = getSearchParams("code");
        const navigateSharedCalender = async (spid, type) => {

            const addProfileUrl = `/addprofile?spid=${spid}&brtype=${type}`;
            const _identificationUrl = `/idverification/upload?spid=${spid}&brtype=${type}`;
            const bookingUrl = `/booking?spid=${spid}&brtype=${type}`;
            const _familyMemberUrl = `/selectfamilymember?spid=${spid}&brtype=${type}`
            const redirectUrl = {
                path: bookingUrl,
                bookingUrl: bookingUrl,
                familyMemberUrl: _familyMemberUrl,
                profileUrl: addProfileUrl,
            }
            if (!getAuthProfile.getProfileAuth()) {
                sessionStorage.setItem("redirectUrl", JSON.stringify(redirectUrl));
                return navigate(`/security/login?c=y`)
            }

            const accountSettings = await getAccountSetting();

            const _isProfile = await checkUserProfile();
            const _isFamilyMember = await getFamilyList();



            if (!_isProfile?.pofileCompleted) {
                sessionStorage.setItem("redirectUrl", JSON.stringify(redirectUrl));
                navigate(addProfileUrl);
            } else if (isEnabled(accountSettings, "CPI", 1) && !_isProfile?.idVerification) {
                sessionStorage.setItem("redirectUrl", JSON.stringify(redirectUrl));
                navigate(_identificationUrl);
            } else if (_isFamilyMember) {
                navigate(_familyMemberUrl);
            } else {
                navigate(bookingUrl);
            }
        }

        const getStarted = async () => {

            try {
                progParams(true);
                const accountSettings = await getAccountSetting();

                const residingStateRes = await serverGet(`${apiConst.getlicenseregion}`);
                const _specality = await serverGet(apiConst.getproviderspeciality);
                progParams(false);
                const stateRes = residingStateRes?.status == 1 && residingStateRes?.data?.states?.length > 0;
                const specialityList = _specality?.res?.data;
                const isResidingState = isEnabled(accountSettings, "URS", 1) && isEnabled(accountSettings, "SCL", 1);
                const residingId = isResidingState ? `rsid=${patientInfo?.residingStateId}` : ""
                const withoutProvider = appointmentCode == "RW8" || appointmentCode == "GT6";

                const shareCalender = getCurrentResourceAction("RW8", "DSB")
                const consultNowWithoutProvider = getCurrentResourceAction("GT6", "DSB")

                if (appointmentCode) {
                    if (isEnabled(accountSettings, "URS", 1) && isEnabled(accountSettings, "SCL", 1) && stateRes) { // checks for is residing state & location based services is enabled
                        navigate(`/residingstate?id=${specialityList?.length == 1 ? specialityList[0]?.id : "y"}${residingId ? `&${residingId}` : ""}&brtype=${appointmentCode}`); //checks speciality length
                    } else if (isEnabled(accountSettings, "ENSP", 1)) {
                        if (specialityList?.length == 1) {

                            if ((withoutProvider && (actionExists(shareCalender, resourceConst?.actions?.create) || actionExists(consultNowWithoutProvider, resourceConst?.actions?.create)))) {
                                return navigateSharedCalender(specialityList[0]?.id, appointmentCode)
                            }

                            const ProviderRes = await serverGet(`${apiConst.getproviderlist}?spid=${specialityList[0]?.id}&kwd=-`);
                            if (ProviderRes?.status == 1 && ProviderRes?.data && ProviderRes?.data?.length == 1) {
                                return navigate(`/profiledetail?memberid=${ProviderRes?.data[0]?.memberId}&spid=${specialityList[0]?.id}&minP=${ProviderRes?.data[0]?.minimumPrice}&brtype=${appointmentCode}&sinp=t`);
                            }
                            navigate(`/provider?spid=${specialityList[0]?.id}&brtype=${appointmentCode}`);
                        } else {
                            navigate(`/specialty?brtype=${appointmentCode}`);
                        }
                    } else {
                        if (specialityList?.length == 1) {
                            if ((withoutProvider && (actionExists(shareCalender, resourceConst?.actions?.create) || actionExists(consultNowWithoutProvider, resourceConst?.actions?.create)))) {
                                return navigateSharedCalender(specialityList[0]?.id, appointmentCode)
                            }

                            const ProviderRes = await serverGet(`${apiConst.getproviderlist}?spid=${specialityList[0]?.id}&kwd=-`);
                            if (ProviderRes?.status == 1 && ProviderRes?.data && ProviderRes?.data?.length == 1) {
                                return navigate(`/profiledetail?memberid=${ProviderRes?.data[0]?.memberId}&spid=${specialityList[0]?.id}&minP=${ProviderRes?.data[0]?.minimumPrice}&brtype=${appointmentCode}&sinp=t`);
                            }
                        }

                        navigate(`/provider?brtype=${appointmentCode}`);
                    }
                } else {
                    navigate("/dashboard");
                }
                ecnSession("clear");
            } catch (e) {
                console.error(e);
            }
        }
        getStarted();
    }, [])

    return (
        <>

        </>
    )
}