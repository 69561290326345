import React, { useContext, useState, useCallback, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { serverGet, serverPost, serverPut } from '../../../../middleware/http';
import { apiConst } from '../../../../services/constant/constant';
import NotifyContext from '../../../../services/utils/notify';
import ProgressContext from '../../../../services/utils/progress';
import { formValidation, getHtmlContent, getMsg, getSearchParams, removeSearchParam, updateSearchParams } from '../../../../services/utils/validation';
//import FormList from './FormList';
import { useAccountSettings } from '../../../../services/Context/AccSettingContext';
import { useLanguageRes } from '../../../../services/Context/LanguageContext';

export default function CustomForm(props) {
    const { register, handleSubmit, control, reset, setValue, formState: { errors }, watch } = useForm();
    const [formsArr, setFormArr] = useState([]);
    const [formFocus, setFormFocus] = useState('');
    const [formRules, setFormRules] = useState(null);
    const [isWriteForms, setIsWriteForms] = useState(false);

    const { progParams } = useContext(ProgressContext);
    const { notifyToast } = useContext(NotifyContext);
    const { accountDate } = useAccountSettings();

    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const encounter_id = queryParams.get('eid');
    const { getLanguageContent } = useLanguageRes();
    const currentValues = watch();

    const getEncounterFormFields = async () => {

        try {
            progParams(true);


            const form_id = props?.selectedFormId || getSearchParams('frmid'); // Form ID 
            let form_input_id = props?.selectedFormInputId || getSearchParams('frminputid'); // Form INPUT ID

            //console.log(form_id);
            //console.log(form_input_id);
            const fmid = getSearchParams('fmid'); // family Member ID
            if (form_id) {

                //if (props?.level == "intake-flow") {
                //    if (Array.isArray(props?.formsList) && props?.formsList?.length > 0) {
                //        form_input_id = props?.formsList?.find((list) => list?.formId?.toLowerCase() == form_id?.toLowerCase())?.formInputId
                //    } else {
                //        const res = await props?.getForms();
                //        form_input_id = res?.find((list) => list?.formId?.toLowerCase() == form_id?.toLowerCase())?.formInputId
                //    }
                //}

                const returnFormInId = () => {
                    if (props?.level == "intake-flow") {
                        let _formsStr = sessionStorage.getItem("formsStorage");
                        if (_formsStr) {
                            _formsStr = JSON.parse(_formsStr);
                            if (_formsStr[form_id?.toLowerCase()])
                                return `&fipid=${_formsStr[form_id?.toLowerCase()]}`;
                        }
                    }
                    return "";
                }


                setFormArr([]);
                if (location.pathname != "/appointment/summary") {
                    updateSearchParams("frmid", form_id);
                    updateSearchParams("frminputid", form_input_id);
                }
                if (!form_input_id) {
                    removeSearchParam("frminputid");
                }

                let member_id = localStorage.getItem("memberId");
                if (!member_id) {
                    const patient_res = await serverGet(apiConst.patientinfo);
                    if (patient_res?.status == 1 && patient_res?.data && patient_res?.data?.memberId)
                        member_id = patient_res?.data?.memberId;
                }

                const res = await serverGet(`${apiConst.getformlist}${form_id}?mid=${fmid ? fmid : member_id}${encounter_id ? `&eid=${encounter_id}` : ""}${form_input_id ? `&fipid=${form_input_id}` : returnFormInId()}`)

                if (res?.data != null && res?.status == 1) {
                    setTimeout(() => {
                        progParams(false);
                        setFormArr(res?.data);
                    }, 2500)
                    const form_focus = res?.data?.find(item => item?.controlId == 1 || item?.controlId == 2 || item?.controlId == 5 || item?.controlId == 6 || item?.controlId == 14);
                    setFormFocus(form_focus);
                    //if (props?.level == "intake-flow") props?.getForms();
                } else {
                    progParams(false);
                }
            }
        } catch (e) {
            progParams(false);
            console.log(e);
        }

    }
    //console.log(formFocus);
    const defaultValuesUpdate = (data) => {
        if (!data || !Array.isArray(data)) {
            return {};
        }

        return data.reduce((acc, curr) => {
            //console.log(acc, curr)
            acc[curr.id] = curr?.controlId == 5 ? curr?.value ? new Date(curr?.value) : null : curr?.value;
            return acc;
        }, {});
    };


    const handleBack = (e, id, inputId) => {
        e.preventDefault();
        if (props?.level == "intake-flow" && id) {
            props?.setSelectedFormId(id);
            props?.setSelectedFormInputId(inputId);
        } else {
            if (props?.level != "intake-flow") {
                props?.toggeleForm();
                removeSearchParam("frminputid");
            }
        }
    }

    // console.log(currentValues);
    const handleNextUrl = () => {
        const isPharmacy = getSearchParams("isp");
        const _reascode = getSearchParams("reascode");
        const _typeid = getSearchParams("ftypeid");
        const fmid = getSearchParams("fmid");
        const _phaid = getSearchParams("phaid");
        const _snum = getSearchParams("snum");
        const _brtype = getSearchParams("brtype");
        const _rsid = getSearchParams("rsid") // residingStateId




        if (isPharmacy == "t" && _rsid && _rsid != "") {
            navigate(`/pharmacy?memberid=${getSearchParams("memberid")}&isp=${"t"}&ad=${getSearchParams("ad")}&ts=${encodeURIComponent(getSearchParams("ts"))}&m=${getSearchParams("m")}&dn=${getSearchParams("dn")}&r=${getSearchParams("r")}&eid=${getSearchParams("eid")}&schid=${getSearchParams("schid")}&emid=${getSearchParams("emid")}&did=${getSearchParams("did")}&minP=${getSearchParams("minP")}&spid=${getSearchParams("spid")}${getSearchParams("rsid") ? `&rsid=${getSearchParams("rsid")}` : ""}&reascode=${_reascode}${_typeid ? `&ftypeid=${_typeid}` : ""}${fmid ? `&fmid=${fmid}` : ""}${_snum ? `&snum=${_snum}` : ""}&brtype=${_brtype}`);
        } else {
            navigate(`/appointment/summary?memberid=${getSearchParams("memberid")}&isp=${"f"}&ad=${getSearchParams("ad")}${_phaid ? `&phaid=${_phaid}` : ""}&ts=${encodeURIComponent(getSearchParams("ts"))}&m=${getSearchParams("m")}&dn=${getSearchParams("dn")}&r=${getSearchParams("r")}&eid=${getSearchParams("eid")}&schid=${getSearchParams("schid")}&emid=${getSearchParams("emid")}&did=${getSearchParams("did")}&minP=${getSearchParams("minP")}&spid=${getSearchParams("spid")}${getSearchParams("rsid") ? `&rsid=${getSearchParams("rsid")}` : ""}&reascode=${_reascode}${_typeid ? `&ftypeid=${_typeid}` : ""}${fmid ? `&fmid=${fmid}` : ""}${_snum ? `&snum=${_snum}` : ""}&brtype=${_brtype}`);
        }
    }

    const handleSkipAndSubmit = (e) => {
        e?.preventDefault();
        const nextFormId = formRules?.NextFormId?.formId
        const nextFormInputId = formRules?.NextFormId?.formInputId
        if (props?.level == "intake-flow" && nextFormId) {
            props?.setSelectedFormId(nextFormId);
            if (!formRules?.NextFormId?.formInputId) {
                removeSearchParam("frminputid");
            }
            props?.setSelectedFormInputId(nextFormInputId);

        } else if (props?.level == "intake-flow") {
            handleNextUrl();
        } else {
            //props?.toggeleForm();
        }
    }

    const selectForm = (id) => {

        if (Array.isArray(props?.formsList) && props?.formsList?.length > 0) {
            const { previousForm, nextForm, slectedForm } = formRule(id);
            const form_rule = {
                selected: slectedForm,
                previousFormId: previousForm,
                NextFormId: nextForm
            }
            setFormRules(form_rule);
        }
    }


    const formRule = (id) => {

        const _forms = [...props?.formsList];

        const selectedIndex = _forms.findIndex(item => item.formId?.toLowerCase() == id?.toLowerCase());
        const slectedForm = _forms.find((item) => item.formId?.toLowerCase() == id?.toLowerCase())
        const previousIndex = selectedIndex > 0 ? selectedIndex - 1 : null;
        const nextIndex = selectedIndex < _forms.length - 1 ? selectedIndex + 1 : null;

        const previousForm = previousIndex !== null ? _forms[previousIndex] : null;
        const nextForm = nextIndex !== null ? _forms[nextIndex] : null;
        if (slectedForm) {
            document.title = slectedForm?.clientTitle;
            if (slectedForm?.write) {
                setIsWriteForms(true);
            }
        }
        return { previousForm, nextForm, slectedForm };
    };


    const onSubmit = (data) => {
        const formdata = [];
        let forms_Arr = [...formsArr];
        if (isWriteForms) {
            forms_Arr?.forEach(item => {
                if ((Object.keys(data).includes(item.id) || (Object.keys(data).includes(`${item.id}_month`) && Object.keys(data).includes(`${item.id}_year`)))) {
                    if (Array.isArray(data[item.id])) {
                        item.value = data[item.id]?.join('~');
                    } else if (data[item.id] instanceof Date) {
                        item.value = data[item.id];
                    } else if (data.hasOwnProperty(`${item.id}_month`) && data.hasOwnProperty(`${item.id}_year`)) {
                        item.value = `${data[`${item.id}_year`]}~${data[`${item.id}_month`]}`;
                    } else {
                        item.value = data[item.id];
                    }
                }
            });
        }



        forms_Arr?.forEach(item => {
            if (item?.parentId && item?.rule) {
                removeChild(item?.rule, item)
            }
        });

        //console.log(forms_Arr);

        forms_Arr?.filter((item) => item?.controlId !== 3 && item?.controlId !== 4 && item.value != undefined)?.map((list) => {
            formdata.push({
                encounterId: encounter_id || null,
                formId: list?.formId,
                formFieldId: list?.id,
                value: list?.value?.toString(),
                //title: list?.clientTitle
            })
        })

        //console.log(formdata);
        const _inputId = forms_Arr[0]?.formInputId;

        if (_inputId) {
            formUpdate(_inputId, formdata)
        } else {
            formCreate(formdata)
        }
    };


    const formCreate = (data) => {

        progParams(true);
        serverPost(apiConst.formsave, data).then((res) => {
            progParams(false);
            try {
                if (res?.status == '1') {
                    formSessionStorage(data[0].formId, res?.data?.forminputid);
                    if (props?.level != "patient-forms") {
                        removeSearchParam("frminputid");
                    }
                    notifyToast(res.messageCode[0]);
                    handleSkipAndSubmit();
                }
                else {
                    var error = JSON.parse(res.data.errorMessage);
                    notifyToast(error.messageCode[0]);
                }
            } catch (e) {

                console.log(e);
            }
        })

    }

    const formUpdate = (id, data) => {
        progParams(true);
        serverPut(`${apiConst?.formUpdate}${id}`, data).then((res) => {
            progParams(false);
            try {
                if (res?.status == '1') {
                    formSessionStorage(data[0].formId, res?.data?.forminputid);
                    //updateSearchParams("frminputid", res?.data?.forminputid);
                    if (props?.level != "patient-forms") {
                        removeSearchParam("frminputid");
                    }
                    notifyToast(res.messageCode[0]);
                    handleSkipAndSubmit();
                }
                else {
                    var error = JSON.parse(res.data.errorMessage);
                    notifyToast(error.messageCode[0]);
                }
            } catch (e) {

                console.log(e);
            }
        })

    }


    const formSessionStorage = (id, inputId) => {

        let _formsStorage = sessionStorage.getItem("formsStorage");
        let _obj = {}

        if (_formsStorage) {
            _obj = JSON.parse(_formsStorage);
            _obj[id.toLowerCase()] = inputId.toLowerCase();
        } else {
            _obj[id.toLowerCase()] = inputId.toLowerCase();
        }

        sessionStorage.setItem("formsStorage", JSON.stringify(_obj));

    }
    function removeChild(value, ite) {
        try {
            if (value) {
                const temp = formsArr?.filter((list) => list?.id == ite?.parentId);
                if (temp?.length > 0 && temp[0]?.parentId) {
                    if (!temp[0]?.value) {
                        return ite.value = "";
                    } else {
                        const obj = JSON.parse(value);
                        if (obj && obj?.RecordId && obj?.Value) {
                            let field = "";
                            if (typeof obj?.RecordId == "string") {
                                field = currentValues[obj?.RecordId?.toLowerCase()];
                            }
                            if (Array.isArray(field) && field?.length > 0) {
                                let _value = obj?.Value?.split("~");
                                //const res = _value.some(item => field.includes(item));
                                const res = _value.some(item => field?.map(String)?.includes(item?.toString()));
                                if (res) {
                                    return true;
                                }

                            }
                            //if (Array.isArray(obj?.Value?.split("~")) && obj?.Value?.split("~")?.includes(field)) {
                            //    return true;
                            //}
                            if (typeof obj?.Value === 'string' && obj.Value.includes("~") && obj.Value.split("~").includes(field)) {
                                return true;
                            }

                            if (field == obj?.Value) {
                                return true;
                            }
                            return ite.value = "";
                        }
                    }

                } else {
                    const obj = JSON.parse(value);
                    if (obj && obj?.RecordId && obj?.Value) {
                        let field = "";
                        if (typeof obj?.RecordId == "string") {
                            field = currentValues[obj?.RecordId?.toLowerCase()];
                        }
                        if (Array.isArray(field) && field?.length > 0) {
                            let _value = obj?.Value?.split("~");
                            //const res = _value.some(item => field.includes(item));
                            const res = _value.some(item => field?.map(String)?.includes(item?.toString()));
                            if (res) {
                                return true;
                            }
                        }
                        //if (Array.isArray(obj?.Value?.split("~")) && obj?.Value?.split("~")?.includes(field)) {
                        //    return true;
                        //}
                        if (typeof obj?.Value === 'string' && obj.Value.includes("~") && obj.Value.split("~").includes(field)) {
                            return true;
                        }

                        if (field == obj?.Value) {
                            return true;
                        }
                        return ite.value = "";
                    }
                }
            }
        } catch (e) {
            console.error(e)
        }
    }




    //console.log(currentValues);


    function checkChild(value, ite) {
        try {
            if (value) {
                const temp = formsArr?.filter((list) => list?.id == ite?.parentId);
                if (temp?.length > 0 && temp[0]?.parentId) {
                    const obj = JSON.parse(value);
                    if (obj && obj?.RecordId && obj?.Value) {
                        let field = "";
                        if (typeof obj?.RecordId == "string") {
                            field = currentValues[obj?.RecordId?.toLowerCase()];
                        }
                        if (Array.isArray(field) && field?.length > 0) {
                            let _value = obj?.Value?.split("~");

                            //const _res = _value.some(item => field.includes(item));
                            const _res = _value.some(item => field?.map(String)?.includes(item?.toString()));

                            if (_res) {
                                return checkChild(temp[0]?.rule, temp[0]);

                            } else {
                                return false;
                            }

                        }
                        //if (Array.isArray(obj?.Value?.split("~")) && obj?.Value?.split("~")?.includes(field)) {
                        //    return checkChild(temp[0]?.rule, temp[0]);

                        //    //return true;
                        //}

                        if (typeof obj?.Value === 'string' && obj.Value.includes("~") && obj.Value.split("~").includes(field)) {
                            return checkChild(temp[0]?.rule, temp[0]);
                        }

                        if (field == obj?.Value) {
                            return checkChild(temp[0]?.rule, temp[0]);

                            //return true;
                        }
                        return false;
                    } return false;
                } else {
                    const obj = JSON.parse(value);
                    if (obj && obj?.RecordId && obj?.Value) {

                        let field = "";
                        if (typeof obj?.RecordId == "string") {
                            field = currentValues[obj?.RecordId?.toLowerCase()];
                        }
                        if (Array.isArray(field) && field?.length > 0) {
                            let _value = obj?.Value?.split("~");
                            //return _value.some(item => field.includes(item));
                            return _value.some(item => field?.map(String)?.includes(item?.toString()));
                        }
                        //if (Array.isArray(obj?.Value?.split("~")) && obj?.Value?.split("~")?.includes(field)) {
                        //    return true;
                        //}

                        if (typeof obj?.Value === 'string' && obj.Value.includes("~") && obj.Value.split("~").includes(field)) {
                            return true;
                        }

                        if (field == obj?.Value) {
                            return true;
                        }
                        return false;
                    } return false;
                }
            }
        } catch (e) {
            console.error(e)
        }
    }

    const getDisabledField = (prop, isWrite) => {
        if (prop?.encounter?.statusId == 3 || prop?.encounter?.statusId == 4) {
            return true;
        } else if (prop?.level == "encounter-forms" && !prop?.tabData?.write) {
            return true;
        } else if (!isWrite) {
            return true;
        } else {
            return false;
        }

    }

    useEffect(() => {
        if (props?.tabData && props?.tabData?.isForm && props?.tabData?.id) {
            //console.log(props?.tabData);
            props?.setSelectedFormId(props?.tabData?.id);
            props?.setSelectedFormInputId(props?.tabData?.formInputId);
        }


    }, [props?.tabData])


    useEffect(() => {
        setTimeout(() => {
            getEncounterFormFields();
        }, 800)

        return () => {
            setFormArr([]);
            if (props?.level != "intake-flow") {
                removeSearchParam("frmid");
                removeSearchParam("frminputid");
            }

        };

    }, [props?.selectedFormId])


    useEffect(() => {
        const form_id = props?.selectedFormId || getSearchParams('frmid');

        if (form_id) {

            setTimeout(() => {
                selectForm(form_id);
            }, 1000)

        }


    }, [props?.formsList, props?.selectedFormId])

    useEffect(() => {
        const defaultValues = defaultValuesUpdate(formsArr);
        reset(defaultValues);
    }, [reset, formsArr]);


    useEffect(() => {
        //  setFormArr(sampleData2);
    }, [])

    //console.log(props?.tabData)
    //console.log(currentValues)


    return (
        <>
            <div>
                {formsArr?.length > 0 && <h4>{formsArr[0]?.formTitle} </h4>}
                {formsArr?.length > 0 && (
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        {Array.isArray(formsArr) && formsArr?.map((list, index, arr) => (
                            <>

                                <div key={`forms${list?.id}`}>
                                    {(list?.controlId == 1 && ((list.rule && checkChild(list.rule, list)) || (list.parentId == null))) ?
                                        <div className="my-4" key={list?.id}>
                                            <label htmlFor={list?.id} className="form-label d-flex">
                                                {list?.isHtml ? <RenderHtmlTitle title={list?.clientTitle} /> : list?.clientTitle}{list?.isRequired && <span className="text-danger">*</span>}
                                            </label>
                                            <Controller
                                                name={list?.id}
                                                control={control}
                                                defaultValue={list?.value}
                                                rules={
                                                    {
                                                        required: list?.isRequired ? getMsg(301) : false,
                                                        validate: list?.isRequired ? formValidation.validateNoEmptySpaces : undefined,
                                                    }
                                                }
                                                render={({ field }) => (
                                                    <input
                                                        {...field}
                                                        disabled={getDisabledField(props, isWriteForms)}
                                                        type="text"
                                                        autoFocus={formFocus?.id == list?.id ? true : false}
                                                        defaultValue={field?.value}
                                                        className="form-control"
                                                        id={list?.id}
                                                        placeholder={""}
                                                    />
                                                )}
                                            />
                                            {errors[list?.id]?.message && <p className="text-danger">{errors[list?.id]?.message}</p>}
                                        </div>
                                        : ""
                                    }

                                    {/*{(list?.controlId == 2 && ((list.rule && checkChild(list.rule, list)) || (list.parentId == null))) ? (*/}
                                    {/*    <div className="my-4" key={list?.id}>*/}
                                    {/*        {list?.clientTitle != arr[index - 1]?.clientTitle ? <p className="d-flex">{list?.isHtml ? <RenderHtmlTitle title={list?.clientTitle} /> : list?.clientTitle}{(list?.isRequired && list?.clientTitle) && <span className="text-danger">*</span>}</p> : ''}*/}
                                    {/*        {(errors[list?.id]?.message && list?.clientTitle != arr[index - 1]?.clientTitle && list?.clientTitle != "") && <p className="text-danger mt-0">{errors[list?.id]?.message}</p>}*/}
                                    {/*        <input disabled={getDisabledField(props, isWriteForms)}*/}
                                    {/*            defaultChecked={list?.value?.split("~")?.includes(list?.answeredFieldOptionId?.toString()) || false }*/}
                                    {/*            value={list?.fieldOptionId}*/}
                                    {/*            autoFocus={formFocus?.id == list?.id ? true : false}*/}
                                    {/*            type="checkbox"*/}
                                    {/*            className="form-check-input" id={`${list?.id}_${index}`}*/}
                                    {/*            {...register(list?.id, {*/}
                                    {/*                required: list?.isRequired ? getMsg(301) : false,*/}
                                    {/*            })}*/}
                                    {/*        />*/}
                                    {/*        <label className="form-check-label ms-2 d-inline" htmlFor={`${list?.id}_${index}`}>{list?.fieldOption}</label>*/}
                                    {/*        {(errors[list?.id]?.message && list?.clientTitle != arr[index - 1]?.clientTitle && list?.clientTitle == "") && <p className="text-danger mt-0">{errors[list?.id]?.message}</p>}*/}
                                    {/*    </div>*/}
                                    {/*)*/}
                                    {/*    : ''*/}
                                    {/*}*/}

                                    {(list?.controlId == 2 && ((list.rule && checkChild(list.rule, list)) || (list.parentId == null))) ?
                                        <div>
                                            {list?.clientTitle != arr[index - 1]?.clientTitle ? (
                                                <>
                                                    <VerticalCheckbox arr={arr} id={list?.id} errors={errors} register={register} control={control}
                                                        setValue={setValue} formFocus={formFocus} getDisabledField={getDisabledField(props, isWriteForms)} />
                                                </>
                                            ) : ''}
                                        </div>
                                        : ''
                                    }


                                    {((list?.controlId == 3) && ((list.rule && checkChild(list.rule, list)) || (list.parentId == null))) ?
                                        <div className="my-4" key={list?.id}>
                                            <p className='fw-bold'>{list?.clientTitle}</p>
                                        </div> : ''
                                    }

                                    {((list?.controlId == 4) && ((list.rule && checkChild(list.rule, list)) || (list.parentId == null))) ?
                                        < div className=" border border-2 rounded rounded-2 p-4" key={list?.id}>
                                            <div className={`${list?.styleId == '3' ? "scroll-list-container bg-light text-dark p-3" : ''}`} dangerouslySetInnerHTML={{ __html: list?.fieldOption }}>
                                            </div>
                                        </div>
                                        : ""
                                    }

                                    {((list?.controlId == 5) && ((list.rule && checkChild(list.rule, list)) || (list.parentId == null))) ?
                                        <div className="my-4" key={list?.id}>
                                            <label className="form-label" htmlFor={`${list?.id}`}>{list?.clientTitle}{list?.isRequired && <span className="text-danger star">*</span>}</label>
                                            <Controller
                                                name={list?.id}
                                                control={control}

                                                //defaultValue={new Date()}
                                                defaultValue={list?.value ? new Date(list?.value) : null}
                                                rules={{ required: list?.isRequired ? getMsg(301) : false }}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        autoFocus={formFocus?.id == list?.id ? true : false}
                                                        name={list?.id}
                                                        id={list?.id}
                                                        selected={field.value}
                                                        disabled={getDisabledField(props, isWriteForms)}
                                                        dateFormat={accountDate?.value ? accountDate?.value?.toLowerCase()?.replace(/mm/, "MM") : "MM/dd/yyyy"}
                                                        className="form-control"
                                                        onChange={field.onChange}
                                                        placeholderText={list?.clientTitle}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        wrapperClassName="w-100"
                                                        dropdownMode="select"
                                                    // maxDate={new Date()}
                                                    />
                                                )}
                                            />
                                            {errors[list?.id]?.message && <p className="text-danger">{errors[list?.id]?.message}</p>}
                                        </div>
                                        : ""
                                    }

                                    {(list?.controlId == 6 && ((list.rule && checkChild(list.rule, list)) || (list.parentId == null))) ?
                                        <div className="my-4" key={list?.id}>


                                            {list?.clientTitle != arr[index - 1]?.clientTitle ? <p className="d-flex">{list?.isHtml ? <RenderHtmlTitle title={list?.clientTitle} /> : list?.clientTitle}{(list?.isRequired && list?.clientTitle) && <span className="text-danger">*</span>}</p> : ''}
                                            {(errors[`${list?.id}`]?.message && list?.clientTitle != arr[index - 1]?.clientTitle && list?.clientTitle != '') && <p className="text-danger mt-0">{errors[`${list?.id}`]?.message}</p>}
                                            <input
                                                disabled={getDisabledField(props, isWriteForms)}
                                                defaultChecked={list?.fieldOptionId == list?.answeredFieldOptionId ? true : list?.fieldOptionDefault ? true : false}
                                                value={list?.fieldOptionId} autoFocus={formFocus?.id == list?.id ? true : false}
                                                type="radio"
                                                className="form-check-input" id={`${list?.id}_${index}`}
                                                {...register(list?.id, {

                                                    required: list?.isRequired ? getMsg(301) : false
                                                })} />

                                            <label className="form-check-label ms-2 d-inline" htmlFor={`${list?.id}_${index}`}>{list?.fieldOption}</label>
                                            {errors[list?.id]?.message && list?.clientTitle != arr[index - 1]?.clientTitle && list?.clientTitle == '' && <p className="text-danger">{errors[list?.id]?.message}</p>}
                                        </div>
                                        : ''
                                    }

                                    {((list?.controlId == 7 && list?.value) && ((list.rule && checkChild(list.rule, list)) || (list.parentId == null))) ?
                                        <div className="my-4" key={list?.id}>
                                            <label htmlFor={list?.id} className="form-label">
                                                {list?.clientTitle}
                                            </label>
                                            <Controller
                                                name={`${list.id}`}
                                                control={control}
                                                defaultValue={list?.value}
                                                render={({ field }) => (
                                                    <input
                                                        {...field}
                                                        disabled
                                                        type="text"
                                                        className="form-control"
                                                        id={`${list.id}`}
                                                    />
                                                )}
                                            />
                                        </div>
                                        : ""
                                    }

                                    {(list?.controlId == 8 && ((list.rule && checkChild(list.rule, list)) || (list.parentId == null))) ?
                                        <div>
                                            <label htmlFor={list.id} className="form-label">
                                                {list?.clientTitle}{list?.isRequired && <span className="text-danger">*</span>}
                                            </label>
                                            <div className="my-4 d-flex">
                                                <div className="input-group m-0 me-sm-2">
                                                    <Controller
                                                        name={`${list.id}_year`}
                                                        control={control}
                                                        defaultValue={Array.isArray(list?.value?.split("~")) && list.value.split("~")?.length > 0 ? list.value.split("~")[0] : ""}
                                                        rules={
                                                            {
                                                                required: list?.isRequired ? getMsg(301) : false,
                                                                pattern: {
                                                                    value: /^(?:\b\d{1,2}|0[0-9])$/,
                                                                    message: "Please enter a number from 0 to 99"
                                                                }
                                                            }
                                                        }
                                                        render={({ field }) => (
                                                            <input
                                                                {...field}
                                                                disabled={getDisabledField(props, isWriteForms)}
                                                                type="text"
                                                                className="form-control"
                                                                id={`${list.id}_year`}
                                                                placeholder="Year"
                                                            />
                                                        )}
                                                    />
                                                    <span className="input-group-text">Year(s)</span>
                                                </div>
                                                <div className="input-group">
                                                    <Controller
                                                        name={`${list.id}_month`}
                                                        control={control}
                                                        rules={
                                                            {
                                                                required: list?.isRequired ? getMsg(301) : false,
                                                            }
                                                        }
                                                        defaultValue={Array.isArray(list?.value?.split("~")) && list.value.split("~")?.length > 1 ? list.value.split("~")[1] : ""}
                                                        render={({ field }) => (
                                                            <select {...field} className="form-select p-2" id={`${list.id}_month`}
                                                                disabled={getDisabledField(props, isWriteForms)}
                                                            >
                                                                <option value="">Month</option>
                                                                <option value="0">0</option>
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="6">6</option>
                                                                <option value="7">7</option>
                                                                <option value="8">8</option>
                                                                <option value="9">9</option>
                                                                <option value="10">10</option>
                                                                <option value="11">11</option>
                                                            </select>
                                                        )}
                                                    />
                                                    <span className="input-group-text">Month(s)</span>
                                                </div>
                                            </div>
                                            {(errors[`${list?.id}_year`]?.message || errors[`${list?.id}_month`]?.message) && (
                                                <p className="text-danger">{errors[`${list?.id}_year`]?.message || errors[`${list?.id}_month`]?.message}</p>
                                            )}
                                        </div>
                                        : ""
                                    }
                                    {((list?.controlId == 9 || list?.controlId == 10 || list?.controlId == 11) && ((list.rule && checkChild(list.rule, list)) || (list.parentId == null))) ?
                                        <div key={list?.id} className="mb-2">
                                            <span className={`${getHeadingClass(list?.controlId)} fw-normal`}>{list?.clientTitle}</span>
                                        </div> : ''
                                    }
                                    {((list?.controlId == 12 && list?.clientTitle && list?.clientTitle?.trim()) && ((list.rule && checkChild(list.rule, list)) || (list.parentId == null))) ?
                                        <div key={list?.id} className="mb-2" dangerouslySetInnerHTML={{ __html: getHtmlContent(list?.clientTitle) }}>
                                        </div> : ''
                                    }


                                    {(list?.controlId == 13 && ((list.rule && checkChild(list.rule, list)) || (list.parentId == null))) ?
                                        <div>
                                            {list?.clientTitle != arr[index - 1]?.clientTitle ? (
                                                <>
                                                    <SelectDropdownField arr={arr} id={list?.id} formFocus={formFocus} getDisabledField={getDisabledField(props, isWriteForms)} errors={errors} register={register} getLanguageContent={getLanguageContent} />
                                                </>
                                            ) : ''}
                                        </div>
                                        : ''
                                    }

                                    {(list?.controlId == 14 && ((list.rule && checkChild(list.rule, list)) || (list.parentId == null))) ?
                                        <div>
                                            {list?.clientTitle != arr[index - 1]?.clientTitle ? (
                                                <>
                                                    <HorizontalRadioField arr={arr} id={list?.id} formFocus={formFocus}  errors={errors} register={register} getDisabledField={getDisabledField(props, isWriteForms)} getLanguageContent={getLanguageContent} />
                                                </>
                                            ) : ''}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </>
                        ))}
                        <div className="mb-2 text-end">
                            {(formRules?.selected && formRules?.selected?.isRequired !== true && props?.level == "intake-flow") && (
                                <button className="btn btn-secondary me-2" onClick={(e) => handleSkipAndSubmit(e)}>Skip</button>
                            )}
                            {((formRules?.previousFormId || props?.level == "patient-forms") && (props?.level != "encounter-forms")) && (
                                <button className={`btn ${props?.level == "patient-forms" ? "btn-lg" : ""}  btn-secondary me-2`} onClick={(e) => handleBack(e, formRules?.previousFormId?.formId, formRules?.previousFormId?.formInputId)}>Back</button>
                            )}
                            {(props?.encounter?.statusId != 3 && props?.encounter?.statusId != 4 && (props?.level == "encounter-forms" ? props?.tabData?.write && isWriteForms : isWriteForms)) && (
                                <button className={`btn ${props?.level == "patient-forms" ? "btn-lg" : ""} btn-primary me-2`}>{getLanguageContent("submt")}</button>
                            )}
                        </div>
                    </form>
                )}
                {props?.summaryPage && <hr />}
            </div>
        </>
    )
}

export const getHeadingClass = (id) => {
    switch (id) {
        case 9:
            return "h4"
        case 10:
            return "h5"
        case 11:
            return "h6"
        default:
            return ""
    }

}

export const RenderHtmlTitle = ({ title }) => {
    return (
        <>
            <span dangerouslySetInnerHTML={{ __html: title }}></span>
        </>
    )
}

const VerticalCheckbox = ({ arr, id, control, setValue, errors, getDisabledField, formFocus }) => {
    const list = arr.filter((obj, index) => obj.id == id);
    const [checkedValues, setCheckedValues] = useState({});
    useEffect(() => {
        const initialSelectedOptions = list.reduce((acc, item) => {
            if (item.answeredFieldOptionId) {
                acc[item.id] = acc[item.id] || [];
                acc[item.id].push(item.answeredFieldOptionId);
            }
            return acc;
        }, {});

        if (JSON.stringify(initialSelectedOptions) !== JSON.stringify(checkedValues)) {
            setCheckedValues(initialSelectedOptions);
        }
    }, []);

    useEffect(() => {
        Object.keys(checkedValues).forEach(fieldId => {
            const selectedValue = checkedValues[fieldId];
            setValue(fieldId, selectedValue, { shouldValidate: true });
        });
    }, [checkedValues, setValue]);

    const handleCheckChange = (fieldOptionId, fieldId) => {
        setCheckedValues(prevCheckedValues => {
            const newCheckedValues = { ...prevCheckedValues };
            if (!newCheckedValues[fieldId]) {
                newCheckedValues[fieldId] = [];
            }
            const index = newCheckedValues[fieldId].indexOf(fieldOptionId);
            if (index === -1) {
                newCheckedValues[fieldId].push(fieldOptionId);
            } else {
                newCheckedValues[fieldId].splice(index, 1);
            }
            return newCheckedValues;
        });
    };
    return (
        <>
            {(Array.isArray(list) && list?.length > 0) && (
                <>
                    <div className="my-4">
                        <p className="d-flex">{list[0]?.isHtml ? <RenderHtmlTitle title={list[0]?.clientTitle} /> : list[0]?.clientTitle}{(list[0]?.isRequired && list[0]?.clientTitle) && <span className="text-danger">*</span>}</p>
                        {list?.map((item, index) => (
                            <div className="form-check mb-4" key={index}>
                                <Controller
                                    name={list[0]?.id}
                                    control={control}
                                    autoFocus={formFocus?.id == id && index == 0 ? true : false}
                                    rules={{
                                        validate: item?.isRequired
                                            ? value => (Array.isArray(value) && value.length > 0) || getMsg(301)
                                            : undefined
                                    }}
                                    render={({ field }) => (
                                        <>
                                            <input
                                                {...field}
                                                type="checkbox"
                                                className="form-check-input"
                                                id={`${item?.id}_${index}`}
                                                onChange={() => handleCheckChange(item?.fieldOptionId, list[0]?.id)}
                                                checked={Array.isArray(checkedValues[list[0]?.id]) && checkedValues[list[0]?.id]?.includes(item?.fieldOptionId)}
                                                disabled={getDisabledField}
                                                autoFocus={formFocus?.id == id && index == 0 ? true : false}
                                            />
                                            <label className="form-check-label" htmlFor={`${item?.id}_${index}`}>{item?.fieldOption}</label>
                                        </>
                                    )}
                                />
                            </div>

                        ))}
                        {errors[list[0].id]?.message && <p className="text-danger">{errors[list[0].id]?.message}</p>}
                    </div>

                </>
            )}
        </>
    );
};


const HorizontalRadioField = ({ arr, id, errors, register, getDisabledField, formFocus }) => {


    const list = arr.filter((obj, index) => obj.id == id);
   // console.log(formFocus, list)

    return (
        <>
            {(Array.isArray(list) && list?.length > 0) && (
                <>
                    <div className="my-4">
                        <p className="d-flex">{list[0]?.isHtml ? <RenderHtmlTitle title={list[0]?.clientTitle} /> : list[0]?.clientTitle}{(list[0]?.isRequired && list[0]?.clientTitle) && <span className="text-danger">*</span>}</p>
                        {list?.map((item, index) => (

                            <div class="form-check form-check-inline" key={index}>
                                <input
                                    disabled={getDisabledField}
                                    defaultChecked={item?.fieldOptionId == item?.answeredFieldOptionId ? true : item?.fieldOptionDefault ? true : false}
                                    value={item?.fieldOptionId} autoFocus={formFocus?.id == id && index == 0 ? true : false}
                                    type="radio"
                                    className="form-check-input" id={`${item?.id}_${index}`}
                                    {...register(`${[list[0].id]}`, {

                                        required: item?.isRequired ? getMsg(301) : false
                                    })} />

                                <label className="form-check-label" htmlFor={`${item?.id}_${index}`}>{item?.fieldOption}</label>
                            </div>
                        ))}
                        {errors[list[0].id]?.message && <p className="text-danger">{errors[list[0].id]?.message}</p>}
                    </div>

                </>
            )}
        </>
    )
}



const CheckBoxField = ({ arr, id, errors, register, getDisabledField, formFocus }) => {

    const list = arr.filter((obj, index) => obj.id == id);


    return (


        <>
            {(Array.isArray(list) && list?.length > 0) && (
                <>
                    <div className="my-4">
                        <p className="d-flex">{list[0]?.isHtml ? <RenderHtmlTitle title={list[0]?.clientTitle} /> : list[0]?.clientTitle}{(list[0]?.isRequired && list[0]?.clientTitle) && <span className="text-danger">*</span>}</p>
                        {list?.map((item, index) => (

                            <div class="form-check form-check-inline" key={index}>
                                <input
                                    disabled={getDisabledField}
                                    defaultChecked={item?.fieldOptionId == item?.answeredFieldOptionId ? true : item?.fieldOptionDefault ? true : false}
                                    value={item?.fieldOptionId} autoFocus={formFocus?.id == item?.id ? true : false}
                                    type="radio"
                                    className="form-check-input" id={`${item?.id}_${index}`}
                                    {...register(`${[list[0].id]}`, {

                                        required: item?.isRequired ? getMsg(301) : false
                                    })} />

                                <label className="form-check-label" htmlFor={`${item?.id}_${index}`}>{item?.fieldOption}</label>
                            </div>
                        ))}
                        {errors[list[0].id]?.message && <p className="text-danger">{errors[list[0].id]?.message}</p>}
                    </div>

                </>
            )}
        </>
    )
}

const SelectDropdownField = ({ arr, id, errors, register, getLanguageContent, getDisabledField }) => {

    const list = arr.filter((obj) => obj.id == id);
    return (
        <>
            {(Array.isArray(list) && list?.length > 0) && (
                <>
                    <div className="my-4">
                        <label htmlFor="servicetype" className="form-label d-flex">{list[0]?.isHtml ? <RenderHtmlTitle title={list[0]?.clientTitle} /> : list[0]?.clientTitle}{list[0]?.isRequired && <span className="text-danger">*</span>}</label>
                        <select disabled={getDisabledField} autofocus id="servicetype" className="form-control form-select"  {...register(`${[list[0].id]}`, {
                            required: getMsg(301),
                        })}>
                            <option value="">{getLanguageContent("select")}</option>
                            {list?.map((item, index) => (
                                <option value={item?.fieldOptionId} key={index}>
                                    {item?.fieldOption}
                                </option>
                            ))}
                        </select>
                        {errors[list[0].id]?.message && <p className="text-danger">{errors[list[0].id]?.message}</p>}
                    </div>

                </>
            )}
        </>
    )
}

const sampleData2 = [

    {
        "id": "7266a949-eaa8-4fa7-a68f-90b3b237fdce",
        "formId": "588d2ead-95bf-4d99-8685-55f347f6fbde",
        "fieldId": "1bd00f8f-dbd1-4736-bf37-a0d9e70922a2",
        "isRequired": false,
        "order": 5,
        "styleId": 2,
        "formTitle": "Health and Acne Profile Questionnaire Overview",
        "clientTitle": "Are you currently using any contraception?",
        "providerTitle": "Are you currently using any contraception?",
        "controlId": 6,
        "dataTypeId": 1,
        "formInputId": "affba15b-a283-4027-ac39-53eaa43a3a53",
        "formFieldId": "7266a949-eaa8-4fa7-a68f-90b3b237fdce",
        "value": "2",
        "fieldOption": "change1",
        "fieldOptionDefault": false,
        "answeredFieldOptionId": null,
        "fieldOptionId": 1,
        "rule": null,
        "parentId": null
    },
    {
        "id": "7266a949-eaa8-4fa7-a68f-90b3b237fdce",
        "formId": "588d2ead-95bf-4d99-8685-55f347f6fbde",
        "fieldId": "1bd00f8f-dbd1-4736-bf37-a0d9e70922a2",
        "isRequired": false,
        "order": 5,
        "styleId": 2,
        "formTitle": "Health and Acne Profile Questionnaire Overview",
        "clientTitle": "Are you currently using any contraception?",
        "providerTitle": "Are you currently using any contraception?",
        "controlId": 6,
        "dataTypeId": 1,
        "formInputId": "affba15b-a283-4027-ac39-53eaa43a3a53",
        "formFieldId": "7266a949-eaa8-4fa7-a68f-90b3b237fdce",
        "value": "2",
        "fieldOption": "change2",
        "fieldOptionDefault": false,
        "answeredFieldOptionId": null,
        "fieldOptionId": 2,
        "rule": null,
        "parentId": null
    },
    {
        "id": "dd442e03-943d-4644-821c-67520462fe55",
        "formId": "588d2ead-95bf-4d99-8685-55f347f6fbde",
        "fieldId": "1bd00f8f-dbd1-4736-bf37-a0d9e70922a2",
        "isRequired": false,
        "order": 5,
        "styleId": 2,
        "formTitle": "Health and Acne Profile Questionnaire Overview",
        "clientTitle": "Are you currently?",
        "providerTitle": "Are you currently using any contraception?",
        "controlId": 14,
        "dataTypeId": 1,
        "formInputId": "affba15b-a283-4027-ac39-53eaa43a3a53",
        "formFieldId": "dd442e03-943d-4644-821c-67520462fe55",
        "value": "3",
        "fieldOption": "select1",
        "fieldOptionDefault": false,
        "answeredFieldOptionId": null,
        "fieldOptionId": 1,
        "rule": null,
        "parentId": null
    },
    {
        "id": "dd442e03-943d-4644-821c-67520462fe55",
        "formId": "588d2ead-95bf-4d99-8685-55f347f6fbde",
        "fieldId": "1bd00f8f-dbd1-4736-bf37-a0d9e70922a2",
        "isRequired": true,
        "order": 5,
        "styleId": 2,
        "formTitle": "Health and Acne Profile Questionnaire Overview",
        "clientTitle": "Are you currently?",
        "providerTitle": "Are you currently using any contraception?",
        "controlId": 14,
        "dataTypeId": 1,
        "formInputId": "affba15b-a283-4027-ac39-53eaa43a3a53",
        "formFieldId": "7266a949-eaa8-4fa7-a68f-90b3b237fdce",
        "value": "3",
        "fieldOption": "select2",
        "fieldOptionDefault": false,
        "answeredFieldOptionId": null,
        "fieldOptionId": "2",
        "rule": null,
        "parentId": null
    },
    {
        "id": "dd442e03-943d-4644-821c-67520462fe55",
        "formId": "588d2ead-95bf-4d99-8685-55f347f6fbde",
        "fieldId": "1bd00f8f-dbd1-4736-bf37-a0d9e70922a2",
        "isRequired": true,
        "order": 5,
        "styleId": 2,
        "formTitle": "Are you currently using any contraception?",
        "clientTitle": "Are you currently?",
        "providerTitle": "select the dropdown",
        "controlId": 14,
        "dataTypeId": 1,
        "formInputId": "affba15b-a283-4027-ac39-53eaa43a3a53",
        "formFieldId": "7266a949-eaa8-4fa7-a68f-90b3b237fdce",
        "value": "3",
        "fieldOption": "select3",
        "fieldOptionDefault": false,
        "answeredFieldOptionId": null,
        "fieldOptionId": "3",
        "rule": null,
        "parentId": null
    }

]


const sampleData = [
    //{
    //    "id": "e314da14-2ca1-457c-b282-1e3da3b5b48d",
    //    "formId": "2fe6909c-838d-4536-9999-dc55918e95cd",
    //    "fieldId": "3f479164-f530-41b3-919d-6b81a38e375a",
    //    "isRequired": false,
    //    "order": 142,
    //    "styleId": 2,
    //    "formTitle": "Health Assessment",
    //    "clientTitle": "Next Assessment Review Date",
    //    "providerTitle": "Next Assessment Review Date",
    //    "controlId": 5,
    //    "dataTypeId": 1,
    //    "formInputId": "55e1202c-eafb-4ccf-9024-1e7ee6326e05",
    //    "formFieldId": "e314da14-2ca1-457c-b282-1e3da3b5b48d",
    //    "value": "Sat Sep 28 2024 00:00:00 GMT+0530 (India Standard Time)",
    //    "fieldOption": "",
    //    "fieldOptionDefault": null,
    //    "answeredFieldOptionId": null,
    //    "fieldOptionId": null,
    //    "rule": null,
    //    "parentId": null,
    //    "isHtml": false,
    //    "minLength": null,
    //    "maxLength": null,
    //    "groupId": null
    //}

    //{
    //    "id": "2d2207e8-815b-4b8d-8cfd-a2ed95bd3924",
    //    "formId": "588d2ead-95bf-4d99-8685-55f347f6fbde",
    //    "fieldId": "c9607475-830d-4fb8-98f4-fb8b1e63a762",
    //    "isRequired": true,
    //    "order": 0,
    //    "styleId": 2,
    //    "formTitle": "Health and Acne Profile Questionnaire Overview",
    //    "clientTitle": "<div><ol style=\"list-style-type: lower-alpha\"><li><p>To ensure that we provide you with the safest and most effective acne treatment, please complete the Health and Acne Profile Questionnaire. This comprehensive form collects essential details about your health, medication history, and acne treatment preferences.</p></li><li><p><strong>Purpose:</strong><ol style=\"list-style-type: lower-roman\"><li><p><b>Safety:</b> Identify any health conditions or medications that may influence your treatment options.</p></li><li><p><b>Customisation:</b> Tailor a treatment plan specific to your acne history and overall health needs.</p></li><li><p>Please answer all questions accurately on your smart device to help us deliver the best care tailored to your needs.</p></li></ol></p></li></ol></div>",
    //    "providerTitle": "<div><ol style=\"list-style-type: lower-alpha\"><li><p>To ensure that we provide you with the safest and most effective acne treatment, please complete the Health and Acne Profile Questionnaire. This comprehensive form collects essential details about your health, medication history, and acne treatment preferences.</p></li><li><p><strong>Purpose:</strong><ol style=\"list-style-type: lower-roman\"><li><p><b>Safety:</b> Identify any health conditions or medications that may influence your treatment options.</p></li><li><p><b>Customisation:</b> Tailor a treatment plan specific to your acne history and overall health needs.</p></li><li><p>Please answer all questions accurately on your smart device to help us deliver the best care tailored to your needs.</p></li></ol></p></li></ol></div>",
    //    "controlId": 12,
    //    "dataTypeId": 1,
    //    "formInputId": "affba15b-a283-4027-ac39-53eaa43a3a53",
    //    "formFieldId": null,
    //    "value": "",
    //    "fieldOption": "",
    //    "fieldOptionDefault": null,
    //    "answeredFieldOptionId": null,
    //    "fieldOptionId": null,
    //    "rule": null,
    //    "parentId": null
    //},
    //{
    //    "id": "dd442e03-943d-4644-821c-67520462fe55",
    //    "formId": "588d2ead-95bf-4d99-8685-55f347f6fbde",
    //    "fieldId": "1c0d1ca4-7e62-43df-82fa-7ff02b78ecae",
    //    "isRequired": true,
    //    "order": 4,
    //    "styleId": 2,
    //    "formTitle": "Health and Acne Profile Questionnaire Overview",
    //    "clientTitle": "Current Contraception Practice",
    //    "providerTitle": "Current Contraception Practice",
    //    "controlId": 9,
    //    "dataTypeId": 1,
    //    "formInputId": "affba15b-a283-4027-ac39-53eaa43a3a53",
    //    "formFieldId": null,
    //    "value": "",
    //    "fieldOption": "",
    //    "fieldOptionDefault": null,
    //    "answeredFieldOptionId": null,
    //    "fieldOptionId": null,
    //    "rule": null,
    //    "parentId": null
    //},
    //{
    //    "id": "7266a949-eaa8-4fa7-a68f-90b3b237fdce",
    //    "formId": "588d2ead-95bf-4d99-8685-55f347f6fbde",
    //    "fieldId": "1bd00f8f-dbd1-4736-bf37-a0d9e70922a2",
    //    "isRequired": true,
    //    "order": 5,
    //    "styleId": 2,
    //    "formTitle": "Health and Acne Profile Questionnaire Overview",
    //    "clientTitle": "Are you currently using any contraception?",
    //    "providerTitle": "Are you currently using any contraception?",
    //    "controlId": 16,
    //    "dataTypeId": 1,
    //    "formInputId": "affba15b-a283-4027-ac39-53eaa43a3a53",
    //    "formFieldId": "7266a949-eaa8-4fa7-a68f-90b3b237fdce",
    //    "value": "",
    //    "fieldOption": "Yes",
    //    "fieldOptionDefault": false,
    //    "answeredFieldOptionId": null,
    //    "fieldOptionId": 1,
    //    "rule": null,
    //    "parentId": null
    //},
    //{
    //    "id": "7266a949-eaa8-4fa7-a68f-90b3b237fdce",
    //    "formId": "588d2ead-95bf-4d99-8685-55f347f6fbde",
    //    "fieldId": "1bd00f8f-dbd1-4736-bf37-a0d9e70922a2",
    //    "isRequired": true,
    //    "order": 5,
    //    "styleId": 2,
    //    "formTitle": "Health and Acne Profile Questionnaire Overview",
    //    "clientTitle": "Are you currently using any contraception?",
    //    "providerTitle": "Are you currently using any contraception?",
    //    "controlId": 16,
    //    "dataTypeId": 1,
    //    "formInputId": "affba15b-a283-4027-ac39-53eaa43a3a53",
    //    "formFieldId": "7266a949-eaa8-4fa7-a68f-90b3b237fdce",
    //    "value": "",
    //    "fieldOption": "No",
    //    "fieldOptionDefault": false,
    //    "answeredFieldOptionId": null,
    //    "fieldOptionId": 2,
    //    "rule": null,
    //    "parentId": null
    //}


]