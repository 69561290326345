import React, { useState, useEffect, useContext } from "react";
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { serverGet, serverPut } from "../../../middleware/http";
import { apiConst, appConst } from "../../../services/constant/constant";
import { ecnSession, getAuthProfile, getMsg, getSearchParams, isEnabled, updateSearchParams } from "../../../services/utils/validation";
import ProgressContext from "../../../services/utils/progress";
import ScheduleHeader from "../consultation/summary/ScheduleHeader";
import { useLanguageRes } from '../../../services/Context/LanguageContext';
import NotifyContext from "../../../services/utils/notify";
import { useProfile } from "../../../services/utils/profileContext";
import { useAccountSettings } from "../../../services/Context/AccSettingContext";

export default function ResidingState() {

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [residingState, setResidingState] = useState([]);
    const [residingStateId, setResidingStateId] = useState([]);
    //const [speciality, setSpeciality] = useState([]);
    //const accountSettingCode = "ENSP";
    const navigate = useNavigate();
    const { progParams } = useContext(ProgressContext);
    const { getLanguageContent } = useLanguageRes();
    const { notifyToast } = useContext(NotifyContext);
    const { getPatient, patientInfo, checkUserProfile, getFamilyList } = useProfile();
    const { accountSettings } = useAccountSettings();

    const member_id = localStorage.getItem("memberId");


    // console.log(getPatient)

    const onSubmit = async (data) => {
        //ecnSession(data?.residingId, "resid");

        const formadata = {
            residingStateId: data?.residingId,
            memberId: member_id
        }
        //updtaeResiding

        try {
            if (getAuthProfile.getProfileAuth() && patientInfo?.residingStateId != getSearchParams("rsid")) {
                progParams(true);
                const res = await serverPut(`${apiConst.updateResiding}`, formadata);
                progParams(false);
                if (res?.status == 1) {
                    getPatient();
                    notifyToast(res.messageCode[0]);
                    navigateResidingState(data);
                } else {
                    var error = JSON.parse(res?.data?.errorMessage);
                    notifyToast(error?.messageCode[0]);
                }
            } else {
                navigateResidingState(data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const navigateResidingState = async (data) => {
        try {
            const _brtype = getSearchParams("brtype");

            const withoutProvider = _brtype == "RW8" || _brtype == "GT6"

            progParams(true);
            const speciality_res = await serverGet(`${apiConst.getproviderspeciality}?sid=${data?.residingId}`);
            progParams(false);
            if (isEnabled(accountSettings, "ENSP", 1)) {
                if (speciality_res?.status == 1 && speciality_res?.data) {
                    if (speciality_res?.data?.length == 1) {
                        if (withoutProvider) {
                            return navigateSharedCalender(speciality_res?.data[0]?.id, data?.residingId, _brtype);
                        }

                        const ProviderRes = await serverGet(`${apiConst.getproviderlist}?spid=${speciality_res?.data[0]?.id}&kwd=-${getSearchParams("rsid") ? `&rsid=${getSearchParams("rsid")}` : ""}`);
                        if (ProviderRes?.status == 1 && ProviderRes?.data && ProviderRes?.data?.length == 1) {
                            return navigate(`/profiledetail?memberid=${ProviderRes?.data[0]?.memberId}&spid=${speciality_res?.data[0]?.id}${getSearchParams("rsid") ? `&rsid=${getSearchParams("rsid")}` : ""}&minP=${ProviderRes?.data[0]?.minimumPrice}&brtype=${_brtype}&sinp=t`);
                        }
                        navigate(`/provider?spid=${speciality_res?.data[0]?.id}&rsid=${data?.residingId}&spln=${speciality_res?.data?.length}&brtype=${_brtype}`);
                    } else {
                        navigate(`/specialty?rsid=${data?.residingId}&brtype=${_brtype}`);
                    }
                }
            } else {
                if (withoutProvider) {

                    return navigateSharedCalender(speciality_res?.data[0]?.id, data?.residingId, _brtype);
                }
                navigate(`/provider?rsid=${data?.residingId}&brtype=${_brtype}`);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const navigateSharedCalender = async (spid, residingId, type) => {
        const addProfileUrl = `/addprofile?spid=${spid}${residingId ? `&rsid=${residingId}` : ""}&brtype=${type}`;
        const _identificationUrl = `/idverification/upload?spid=${spid}${residingId ? `&rsid=${residingId}` : ""}&brtype=${type}`;
        const bookingUrl = `/booking?spid=${spid}${residingId ? `&rsid=${residingId}` : ""}&brtype=${type}`;
        const _familyMemberUrl = `/selectfamilymember?spid=${spid}${residingId ? `&rsid=${residingId}` : ""}&brtype=${type}`

        const redirectUrl = {
            path: bookingUrl,
            bookingUrl: bookingUrl,
            familyMemberUrl: _familyMemberUrl,
            profileUrl: addProfileUrl,
        }
        if (!getAuthProfile.getProfileAuth()) {
            sessionStorage.setItem("redirectUrl", JSON.stringify(redirectUrl));

            return navigate(`/security/login?c=y${residingId ? `&${residingId}` : ""}`)
        }

        const _isProfile = await checkUserProfile();
        const _isFamilyMember = await getFamilyList();

    

        if (!_isProfile?.pofileCompleted) {
            sessionStorage.setItem("redirectUrl", JSON.stringify(redirectUrl));
            navigate(addProfileUrl);
        } else if (isEnabled(accountSettings, "CPI", 1) && !_isProfile?.idVerification) {
            sessionStorage.setItem("redirectUrl", JSON.stringify(redirectUrl));
            navigate(_identificationUrl);
        } else if (_isFamilyMember) {
            navigate(_familyMemberUrl);
        } else {
            navigate(bookingUrl);
        }
    }


    const getResidalState = () => {
        //const sessionStore = ecnSession();
        progParams(true);
        serverGet(`${apiConst.getlicenseregion}`).then((res) => {
            progParams(false);
            try {
                if (res?.status == 1) {
                    if (Array.isArray(res?.data?.states)) {
                        const state_id = res?.data?.states?.find(em => em?.id == res?.data?.memberStateId);
                        if (getSearchParams("rsid")) {
                            setValue("residingId", getSearchParams("rsid"));
                            setResidingStateId(getSearchParams("rsid"));
                        } else if (state_id && res?.data?.memberStateId) {
                            setValue("residingId", res?.data?.memberStateId);
                            setResidingStateId(res?.data?.memberStateId);
                        }
                        setResidingState(res?.data);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        })
    }



    useEffect(() => {
        document.title = "Residing state";
        getResidalState();
        //getSetting();
        serverGet("externalorder/getsessdetail").then(res => {
            if (res?.orderDetail) {
                localStorage.setItem("orderDetail", res.orderDetail);
            }
        });

    }, []);

    useEffect(() => {
        if (residingStateId) {
            setValue("residingId", residingStateId);
        }
    }, [residingStateId]);

    return (
        <>
            <ScheduleHeader title={getLanguageContent("crntlct")} />
            <div className="mb-3">
                <small className="text-secondary">{getLanguageContent("slctcrntlct")}</small>
            </div>
            <div className="card border-0 p-2 mt-3">
                <div className="card-body">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-xl-9 col-xxl-8">
                            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                <div className="my-3">
                                    <label htmlFor="residingId" className="form-label">{getLanguageContent("curntresdngstate")}<span className="text-danger star">*</span></label>
                                    <select autoFocus={true} className="form-control form-select" id="residingId"  {...register("residingId", {
                                        required: getMsg(301),
                                        onChange: (e) => updateSearchParams("rsid", e.target.value)

                                    })}>
                                        <option value=""><span>{getLanguageContent("select")}</span></option>
                                        {residingState?.states?.length > 0 && residingState?.states?.map((list, i) => (
                                            <option key={i} value={list?.id}>{list?.name}</option>
                                        ))}
                                    </select>
                                    {errors.residingId?.message && <span className="text-danger">{errors.residingId?.message}</span>}
                                </div>
                                <div className="text-end">
                                    <button type="submit" className="btn btn-lg btn-primary">{getLanguageContent("continue")}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}