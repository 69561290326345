import React from "react";
import { useLanguageRes } from "../../../../../services/Context/LanguageContext";
import DoctorSummary from "../../../consultation/summary/DoctorSummary";
import ScheduleHeader from "../../../consultation/summary/ScheduleHeader";
import Forms from "../forms";
import { appConst } from "../../../../../services/constant/constant";


export default function IntakeForms() {

    const { getLanguageContent } = useLanguageRes();


    return (
        <>
            <div className="px-2 px-md-0">
                <ScheduleHeader title={"Intake forms"} />
                <div className="d-flex flex-md-row flex-column">
                    <DoctorSummary />
                    <div className="flex-grow-1">
                        <Forms level="intake-flow" formShowState={true} areaId={appConst.areaId.intakeForms } />
                    </div>
                </div>
            </div>
        </>
    )
}