import React, { useContext } from 'react'
import NotifyContext from '../../../services/utils/notify';
import ProgressContext from '../../../services/utils/progress';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { serverGet, serverPost, serverFormPost, serverAPIPost } from '../../../middleware/http';
import { PasswordField } from '../../../services/utils/forms';
import { formValidation, getAuthProfile, getMsg, getSearchParams, isEnabled } from '../../../services/utils/validation';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { apiConst } from '../../../services/constant/constant';
import { useLanguageRes } from '../../../services/Context/LanguageContext';
import { useAccountSettings } from '../../../services/Context/AccSettingContext';
import TermsAndPolicy from './TermsAndPolicy';

export default function Signup() {
    const { register, handleSubmit, getValues, formState: { errors } } = useForm();
    const { notifyToast } = useContext(NotifyContext)
    const { progParams } = useContext(ProgressContext);
    const { getLanguageContent } = useLanguageRes();
    const { accountSettings } = useAccountSettings();

    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const visit_detail_page = getSearchParams("isrp") || false; // visit detail page navigate back to reason page to complete apointment t == yes else null or undefined
    const encounter_id = queryParams.get('eid');
    const member_id = queryParams.get('mid');
    const firstName = queryParams.get('fn');
    const lastName = queryParams.get('ln');
    const email = queryParams.get('em');
    const bookNow_click = queryParams.get('c'); //y == yes else null or undefined
    const residingId = getSearchParams("rsid") || location?.state?.id || "";

    const redirectUrl = sessionStorage.getItem("redirectUrl");
    let to_redirect_uri = getSearchParams("to_redirect_uri");
    let redirect_uri = getSearchParams("redirect_uri");

    const authenticationUrl = sessionStorage.getItem("redirectAuthentication");

    //console.log(residingId);
    let isClientRedirect = getSearchParams("client_redirect");

    isClientRedirect = (isClientRedirect !== null && typeof isClientRedirect !== "undefined") ? true : false;
    let ssoQueryParams = null;

    if (isClientRedirect) {
        let strgeObj = sessionStorage.getItem("sso_redirect_uri");
        if (strgeObj != null && typeof strgeObj !== "undefined") {
            ssoQueryParams = JsonToURL(JSON.parse(strgeObj));
        }
    }


    
    const onSubmit = async (data) => {

        const currentDate = new Date();

        const formadata = {
            firstname: data?.firstname,
            lastname: data?.lastname,
            username: data?.username, //email
            password: data?.password,
            isTermsAccepted: data?.termscond,
            termsAcceptedOn: currentDate
        }

        if (member_id) {
            formadata.MemberId = member_id
        }

        progParams(true);
        try {
            let sso_redirect_json = sessionStorage.getItem("sso_redirect_uri");
            sso_redirect_json = JSON.parse(sso_redirect_json);

            const res = await serverPost(apiConst.postregister, formadata);
            progParams(false);
            if (res == true) {
                getAuthProfile.saveProfileAuth();
                if (isClientRedirect) {
                    progParams(true);
                    (async () => {
                        var ssoFrmData = new FormData();
                        ssoFrmData.append("client_id", sso_redirect_json.client_id);
                        ssoFrmData.append("client_secret", sso_redirect_json.client_secret);
                        ssoFrmData.append("grant_type", "password");
                        ssoFrmData.append("username", formadata.username);
                        ssoFrmData.append("password", formadata.password);

                        await serverFormPost(`${apiConst.ssologin}`, ssoFrmData, false).then(reslt => {
                            try {
                                if (reslt?.access_token) {
                                    (async () => {
                                        sessionStorage.setItem("id_ref", sso_redirect_json.client_id);
                                         await serverAPIPost(`${apiConst.authorizelogin}`, { "Value": reslt.access_token }).then(tokResult => {
                                             if (tokResult) {
                                                 if (typeof to_redirect_uri === "undefined")
                                                     to_redirect_uri = "";
                                                console.log("redirecting ..");
                                                return window.location.href = `${sso_redirect_json.redirect_uri}&code=${reslt.access_token}&state=b3BlbmlkY29ubmVjdA%3D%3D&to_redirect_uri=${to_redirect_uri}`;//&iss=https%3A%2F%2Flocalhost%3A44324%2F
                                             }
                                        });
                                    })();
                                    //return window.location.href = `${sso_redirect_json.redirect_uri}&code=${reslt.access_token}&state=b3BlbmlkY29ubmVjdA%3D%3D&to_redirect_uri=${sso_redirect_json?.to_redirect_uri}`;
                                } else { progParams(false); notifyToast(311); }
                            } catch (err) { progParams(false); console.log(err) }
                        });
                    })();
                    return;
                }
                if (member_id && encounter_id && visit_detail_page) {
                    //window.history.replaceState(null, '', `/visit/detail?eid=${encounter_id}`);
                    //return window.location.href = `/visit/detail?eid=${encounter_id}`;
                    const res_enc = await serverGet(apiConst.getencounter + encounter_id);

                    return window.location.href = `/addprofile${encounterUrl(res_enc?.data)}`;
                } else if (redirectUrl && redirectUrl !== '' && bookNow_click == "y") {
                    window.history.replaceState(null, '', "/addprofile");
                    return window.location.href = `/addprofile${residingId ? `?rsid=${residingId}` : ""}`;
                } else if (authenticationUrl && authenticationUrl?.trim() !== "") {
                    const url = JSON.parse(authenticationUrl);
                    sessionStorage.removeItem("redirectAuthentication");
                    if (url?.includes("/security")) {
                        return window.location.href = "/dashboard";
                    }
                    return window.location.href = url;
                }
                //localStorage.removeItem("redirectUrl");
                //window.history.replaceState(null, '', "/dashboard");
                return window.location.href = "/dashboard";
            }
            else {
                var error = JSON.parse(res.data.errorMessage);
                notifyToast(error.messageCode[0]);
            }
        } catch (e) {
            console.log(e);
        }

    };

    function encounterUrl(res_enc) {
        const memberid = res_enc?.providerId;
        const did = res_enc?.durationId;
        const emid = res_enc?.modeId;
        const ts = res_enc?.scheduleOn;
        const spid = res_enc?.specialityId;
        const rsid = residingId;
        const rnote = res_enc?.note;
        const rid = res_enc?.reasonId;
        const r = res_enc?.reason;
        const scheId = res_enc?.scheduleId;
        const statusId = res_enc?.statusId;
        const eid = getSearchParams("eid");
        
        return `?memberid=${memberid}&did=${did}&encstatusid=${statusId}&emid=${emid}&ts=${ts}&spid=${spid}&rnote=${rnote}&rid=${rid}&r=${r}&eid=${eid}&scheId=${scheId}${rsid ? `&rsid=${rsid}` : ""}${res_enc?.statusId == 5 ? "&isrp=t" : ""}${res_enc?.serviceOptionId ? `&speicalityservice=${res_enc?.serviceOptionId}` : ""}`

    }

    useEffect(() => {
        document.title = 'Sign up';



        let sso_strgeObj = sessionStorage.getItem("sso_redirect_uri");
        if (sso_strgeObj != null && typeof sso_strgeObj !== "undefined") {
            sso_strgeObj = JSON.parse(sso_strgeObj);
            to_redirect_uri = sso_strgeObj?.redirect_uri;
        }


        if (isEnabled(accountSettings, "DCS", 1)) {
            return navigate("/pagenotfound");
        }

    }, [accountSettings]);

    //console.log(profileContext)




    return (
        <>
            <h6 className="card-title text-uppercase fw-bold">{getLanguageContent("sgnup")}</h6>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="my-3">
                    <label htmlFor="firstname" className="form-label">{getLanguageContent("firstname")}<span className="text-danger">*</span></label>
                    <input type="text" defaultValue={firstName} autoFocus={true} className="form-control body-secondary-color" id="firstname" placeholder={getLanguageContent("entfirstname")} {...register("firstname", {
                        required: getMsg(301),
                        validate: formValidation.validateNoEmptySpaces,
                        maxLength: { value: 100, message: getMsg("305") }
                    })} />
                    {errors.firstname?.message && <span className="text-danger">{errors.firstname?.message}</span>}
                </div>
                <div className="mb-3">
                    <label htmlFor="lastname" className="form-label">{getLanguageContent("lastname")}<span className="text-danger">*</span></label>
                    <input type="text" defaultValue={lastName} className="form-control body-secondary-color" id="lastname" placeholder={getLanguageContent("entlastname")} {...register("lastname", {
                        required: getMsg(301),
                        validate: formValidation.validateNoEmptySpaces,
                        maxLength: { value: 100, message: getMsg("305") }
                    })} />
                    {errors.lastname?.message && <span className="text-danger">{errors.lastname?.message}</span>}
                </div>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">{getLanguageContent("email")}<span className="text-danger">*</span></label>
                    <input type="text" defaultValue={email} className="form-control body-secondary-color" id="email" placeholder="Enter email address" {...register("username", { required: getMsg(301), pattern: formValidation.email.pattern })} />
                    {errors.username?.message && <span className="text-danger">{errors.username?.message}</span>}
                </div>
                <div className="mb-3">
                    <PasswordField
                        name="password"
                        label={getLanguageContent("psswrd")}
                        //label={langResource.getValue("newpwd")}
                        errors={errors}
                        register={register}
                        icon={true}
                        placeholder={getLanguageContent("entpsswrd")}
                        validationSchema={{
                            required: getMsg(301),
                            maxLength: { value: 50, message: getMsg("305") },
                            pattern: formValidation.password.pattern,
                        }}
                    />
                </div>
                <div className="mb-3">
                    <PasswordField
                        name="Confirmpassword"
                        label={getLanguageContent("cnfmpsswrd")}
                        //label={langResource.getValue("cofmpwd")}
                        errors={errors}
                        register={register}
                        placeholder={getLanguageContent("entcnfmpsswrd")}
                        icon={true}
                        validationSchema={{
                            required: getMsg(301),
                            validate: value => value === getValues('password') || getMsg(316),
                            maxLength: {
                                value: 50, message: getMsg("305")
                            }
                        }}
                    />
                </div>

                <div className="mb-3 ">
                    <div className="d-flex">
                        <input type="checkbox" className="form-check-input" id="term&cond" {...register("termscond", { required: getMsg(301) })} />
                        <label className="form-check-label body-secondary-color ms-2">
                            <label htmlFor="term&cond">{getLanguageContent("agterm")}</label>
                            <TermsAndPolicy />
                        </label>
                    </div>
                    {errors.termscond?.message && <span className="text-danger">{errors.termscond?.message}</span>}
                </div>

                <div className="mb-2">
                    <button className="btn btn-primary">{getLanguageContent("signup")}</button>
                </div>
                <div className="col">{getLanguageContent("alrdyaccount")} {ssoQueryParams ?
                    <a className="link" href={"/security/login?" + ssoQueryParams}>{getLanguageContent("signin")}</a> : <Link
                        to={`/security/login${bookNow_click == "y" ? "?c=y" : ""}`}
                        state={{
                            id: residingId ? residingId : ""
                        }}
                    >{getLanguageContent("signin")}</Link>} {getLanguageContent("here")} </div>

            </form>
        </>
    );
}

export const JsonToURL = (jsonObj) => {
    return Object
        .keys(jsonObj)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(jsonObj[k]))
        .join('&');
}



