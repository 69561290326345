import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { serverGet } from "../../../../middleware/http";
import { apiConst } from "../../../../services/constant/constant";
import { dateTimeFormat, getSearchParams, isEnabled, timeFormat } from "../../../../services/utils/validation";
import ScheduleHeader from "./ScheduleHeader";
import LazyImageComponent from "../../../../services/utils/LazyImageComponent";
import { useAccountSettings } from "../../../../services/Context/AccSettingContext";
import VitalList from "../vital/vitalList";
import { useLanguageRes } from "../../../../services/Context/LanguageContext";
import { useDoctor } from "../../../../services/Context/DoctorContext";
import SummaryForm from "../form/SummaryForm/SummaryForm";
import { useProfile } from "../../../../services/utils/profileContext";

export default function EncounterSummary() {

    const [summary, setSummary] = useState(null);
    const [showFormTitle, setShowFormTitle] = useState(false);

    //console.log()
    const [summaryPrice, setSummaryPrice] = useState(null);
    const { getLanguageContent } = useLanguageRes();
    const { accountDate, showAccPricing, AccountCurrency, accountSettings, schedulePricing } = useAccountSettings();
    // const { doctorContext } = useDoctor();
    const { contextFamilyList } = useProfile();
    const pharmacyId = getSearchParams("phaid");
    const _brtype = getSearchParams("brtype");
    const navigate = useNavigate();


    const getSummary = (id) => {
        try {
            const member_id = localStorage.getItem("memberId");

            serverGet(`${apiConst.schedulesummary}${member_id}/${id}`).then((res) => {
                setSummary(res?.data)
            });
        } catch (error) {
            console.error(error);
        }
    }


    const navigatePayment = () => {

        const locationSearch = window.location.search
        navigate(`/payment${locationSearch}`);

    }

    const findFamilyMember = (id) => {


        return contextFamilyList?.find((list) => list?.id == getSearchParams("fmid"));

    }

    useEffect(() => {
        const enc_id = getSearchParams("eid");
        document.title = "Appointment summary";
        //const enc_id = "2d459208-3d4c-424b-958e-a453362dc120";
        //eid=ba298416-7abe-4bcf-98ee-ef1905ac500e&spid=b82fa940-a336-488b-a6f0-c203d7fd5c52
        if (enc_id && enc_id?.trim())
            getSummary(enc_id);
    }, [])



    const handleFormTitle = () => {
        if (!showFormTitle) {
            setShowFormTitle(true);
        }
    }

    useEffect(() => {

        const price = schedulePricing;
        setSummaryPrice(price);

    }, [schedulePricing])


    //console.log(summary?.documents?.filter((list) => list?.typeId == 301)); /payment

    return (
        <>
            {summary && (
                <div>
                    <ScheduleHeader title={getLanguageContent("aptsmy")} description={getLanguageContent("aptrvw")} />

                    <div className="card border-0">
                        <div className="card-body">
                            <div className="d-flex">
                                <div>
                                    <LazyImageComponent
                                        alt="profile-pic"
                                        className="rounded"
                                        src={!summary?.provider?.photoPath ? require('../../../../asset/images/blankuser.png') : "doc/getimage/true?url=" + encodeURIComponent(summary?.provider?.photoPath)}
                                        width="60"
                                    />
                                </div>
                                <div className="ms-3 flex-grow-1 position-relative">
                                    {(summary?.provider?.firstName) && (
                                        <h6 className="fs-5">{summary?.provider?.firstName} {summary?.provider?.lastName}</h6>
                                    )}



                                    <div className="my-2">
                                        <span className="text-secondary">
                                            {summary?.speciality?.name}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <hr />

                            <div className="row my-2">
                                <div className="col-md-6">
                                    <div className="mb-2">
                                        <span>
                                            {getLanguageContent("aptcns")}
                                        </span><br />
                                        <span className="fw-bold">

                                            {(summary?.patient?.firstName && !getSearchParams("fmid")) && (
                                                <>
                                                    {summary?.patient?.firstName} {summary?.patient?.lastName} ({getLanguageContent("aptmse")})
                                                </>
                                            )}

                                            {(getSearchParams("fmid") && contextFamilyList && contextFamilyList?.find((list) => list?.id == getSearchParams("fmid"))) && (
                                                <>
                                                    {`${findFamilyMember(getSearchParams("fmid"))?.firstName} ${findFamilyMember(getSearchParams("fmid"))?.lastName}`} ({findFamilyMember(getSearchParams("fmid"))?.relationship})
                                                </>
                                            )}
                                        </span>
                                    </div>



                                    {summary?.encounterList?.reason && (
                                        <div className="mb-2">
                                            <span>
                                                {getLanguageContent("aptrsn")}
                                            </span><br />
                                            <span className="fw-bold">
                                                {summary?.encounterList?.reason}
                                            </span>
                                        </div>
                                    )}

                                    {(Array.isArray(summary?.symptoms) && summary?.symptoms?.length > 0 && summary?.symptoms?.find((list) => list?.encounterId != null)) && (
                                        <div className="mb-2">
                                            <span>
                                                {"Symptoms"}
                                            </span><br />

                                            <span className="fw-bold">{summary?.symptoms?.filter((list) => list?.encounterId != null)?.map((symptom, i, arr) => {
                                                return <span key={`symptom{i}`}>{symptom?.symptomName}{i != (arr.length - 1) ? ', ' : ''} </span>
                                            })}
                                            </span>
                                        </div>
                                    )}

                                    {summary?.encounterList?.note && (
                                        <div className="mb-2">
                                            <span>
                                                {getLanguageContent("aptnte")}:
                                            </span><br />
                                            <span>
                                                {summary?.encounterList?.note}
                                            </span>
                                        </div>
                                    )}

                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                        {summary?.encounterList?.encounterMode && (
                                            <>
                                                <span>
                                                    {getLanguageContent("aptmnt")} ({summary?.encounterList?.encounterMode})
                                                </span><br />
                                            </>
                                        )}
                                        {summary?.encounterList?.scheduleOn && (
                                            <span className="fw-bold">
                                                {dateTimeFormat(summary?.encounterList?.scheduleOn, accountDate?.value)} {timeFormat(summary?.encounterList?.scheduleOn)}{_brtype != "GT6" && <span> | ({summary?.encounterList?.duration})</span>}
                                            </span>
                                        )}

                                    </div>

                                    {(summary?.encounterList?.phoneNo) && (
                                        <div className="mb-2">
                                            <span>
                                                {getLanguageContent("phonum")}
                                            </span><br />
                                            <span className="fw-bold">
                                                {summary?.encounterList?.phoneNo}
                                            </span>
                                        </div>
                                    )}
                                    {(showAccPricing && AccountCurrency?.value && summaryPrice && parseFloat(summaryPrice) > 0 && isEnabled(accountSettings, "PFS", 1)) ?
                                        <div className="mb-2">
                                            <span>
                                                Payment
                                            </span><br />
                                            <span className="fw-bold">
                                                <span>{AccountCurrency?.value}{parseFloat(summaryPrice)?.toFixed(2)}</span>
                                            </span>
                                        </div> : ""
                                    }
                                    {(summary?.pharmacy || pharmacyId == "00000000-0000-0000-0000-000000000000") && (
                                        <div className="mb-2">
                                            <span>
                                                {getLanguageContent("aptpcy")}
                                            </span><br />
                                            <span>
                                                {pharmacyId == "00000000-0000-0000-0000-000000000000" ? (
                                                    <>
                                                        <span className="fw-semibold">{getLanguageContent("phseukn")}</span><br />
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="fw-semibold">{summary?.pharmacy?.name}</span><br />
                                                        <small>{summary?.pharmacy?.address1 ? ` ${summary?.pharmacy?.address1}` : ""}{summary?.pharmacy?.address2 ? `, ${summary?.pharmacy?.address2}` : ""}{summary?.pharmacy?.city ? `, ${summary?.pharmacy?.city}` : ""}{summary?.pharmacy?.state ? `, ${summary?.pharmacy?.state}` : ""}{summary?.pharmacy?.zipcode ? `, ${summary?.pharmacy?.zipcode}` : ""}</small>
                                                    </>
                                                )}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <hr />
                            <div className="row my-2 ">
                                {Array.isArray(summary?.documents) && summary?.documents?.filter((list) => list?.typeId != 301)?.length > 0 && (
                                    <>
                                        <h4>{getLanguageContent("aptdcm")}</h4>
                                        {summary?.documents?.filter((list) => list?.typeId != 301)?.map((item) => (
                                            <>
                                                <DocumentContainer list={item} />
                                            </>
                                        ))}
                                        <hr />
                                    </>
                                )}
                            </div>
                            <div className="row g-2 g-md-3 my-2">
                                {Array.isArray(summary?.documents) && summary?.documents?.filter((list) => list?.typeId == 301)?.length > 0 && (
                                    <>
                                        <h4>{getLanguageContent("aptpct")}</h4>
                                        {summary?.documents?.filter((list) => list?.typeId == 301)?.map((item) => (
                                            <>
                                                <PictureContainer list={item} />
                                            </>
                                        ))}
                                        <hr />
                                    </>
                                )}
                            </div>

                            {(summary.healthMetric?.healthGroup?.length > 0 && summary.healthMetric?.patientHealthMetrics?.length > 0) && (
                                <div className="my-2">
                                    <VitalList />
                                    <hr />
                                </div>
                            )}
                            {(Array.isArray(summary?.forms) && summary?.forms?.length > 0) && (
                                <>
                                    {showFormTitle && (
                                        <h4>Forms</h4>
                                    )}
                                    {summary?.forms?.map((item) => (
                                        <>
                                            <SummaryForm selectedFormId={item?.formId} formInputId={item?.formInputId} handleFormTitle={handleFormTitle} />
                                        </>
                                    ))}
                                </>
                            )}
                            <div className="my-2 text-end">
                                <button onClick={() => navigatePayment()} className="btn btn-primary btn-lg my-2">{getLanguageContent("aptsmt")}</button>
                            </div>
                        </div>
                    </div >
                </div>
            )
            }
        </>
    )
}

const PictureContainer = ({ list }) => {
    return (
        <div className="col-6 col-sm-4 col-md-3 col-lg-2 ">
            <div className="border rounded p-1 h-100">
                <div className="position-relative d-flex justify-content-center align-items-center h-100 ">
                    {list?.filePath && (
                        <LazyImageComponent
                            src={`doc/getimage/true?url=${encodeURIComponent(list?.filePath)}`}
                            alt={list?.fileName}
                            height="80"
                        />
                    )}
                </div>
            </div>
        </div>
    )
}


const DocumentContainer = ({ list }) => {
    return (
        <div className="col-xl-6 mb-3">
            <div className="d-flex border rounded align-items-center">
                <div className="px-2">
                    <span className="fs-1 text-secondary"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
                        <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
                        <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                    </svg></span>
                </div>
                <div className="py-1 truncate-text flex-grow-1">
                    {list?.fileName}<br />
                </div>
            </div>
        </div>
    )

}