import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { serverGet, serverPost } from '../../../middleware/http';
import { apiConst, appConst } from '../../../services/constant/constant';
import NotifyContext from '../../../services/utils/notify';
import ProgressContext from '../../../services/utils/progress';
import { formValidation, getMsg, getSearchParams } from '../../../services/utils/validation';
import DocumentUpload from '../../fileUpload/DocUpload';
import { ecnSession } from '../../../services/utils/validation';
import ScheduleHeader from '../consultation/summary/ScheduleHeader';
import { useLanguageRes } from '../../../services/Context/LanguageContext';
import { useProfile } from '../../../services/utils/profileContext';


export default function ProofUpload(props) {

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const [cateogry, setCateogry] = useState([]);
    const [proofDoc, setProofDoc] = useState([]);
    const [cateogryType, setCateogryType] = useState("");
    //const [conditionDocs, setConditionDocs] = useState([]);

    const { progParams } = useContext(ProgressContext);
    const { notifyToast } = useContext(NotifyContext);
    const { getFamilyList } = useProfile();

    //const urlSearchParams = new URLSearchParams(window.location.search);
    const member_id = localStorage.getItem("memberId");
    //const residing_state = urlSearchParams.get('r');
    //const sp_id = urlSearchParams.get('id');
    //const profile_completion = urlSearchParams.get('pc');
    const { getLanguageContent } = useLanguageRes();
    const sessionStore = ecnSession();
    const redirectUrl = sessionStorage.getItem("redirectUrl");

    const [profileDocuments, setProfileDocuments] = useState([]);

    const getDocuments = () => {
        progParams(true);
        serverGet(`${apiConst.doclist}${member_id}?recordid=${member_id}&gn=${appConst.cateogries.proofUpload}`).then((res) => {
            progParams(false);
            try {
                if (res?.status == 1 && res?.data !== null) {
                    setProfileDocuments(res?.data);
                }
            } catch (e) {
                console.log(e)
            }
        })
    }


    const onSubmit = (data) => {
        //const urlSearchParams = new URLSearchParams(window.location.search);
        //const profile_completion = urlSearchParams.get('pc');

        //const speciality = urlSearchParams.get('id');
        const sessionStore = ecnSession();
        const sizeInBytes = 10 * 1024 * 1024
        let size_validation = false;
        let type_validation = false;
        let name_validation = false;

        //validation loop

        if (proofDoc.length > 0) {
            proofDoc.forEach((item) => {
                const doc = item.src;
                if (doc.size > sizeInBytes && !formValidation.filename_val.test(doc.name) && !appConst.fileTypes.profile.validationTypes?.includes(doc.type)) {
                    size_validation = true;
                    type_validation = true;
                } else if (!appConst.fileTypes.profile.validationTypes?.includes(doc.type)) {
                    type_validation = true;
                } else if (doc.size > sizeInBytes) {
                    size_validation = true;
                } else if (!formValidation.filename_val.test(doc.name)) {
                    name_validation = true;
                }
            })
        }

        //uploaded files loop

        const document_files = [];
        if (sessionStore && sessionStore?.uploadid && sessionStore?.uploadid?.length > 0) {
            for (const doc of sessionStore?.uploadid) {
                if (window.location.pathname == doc?.type) {
                    document_files.push({
                        memberId: member_id,
                        recordTypeId: appConst.recordType.proofUpload,
                        fileName: doc?.name,
                        filePath: doc?.src,
                        note: data?.note,
                        typeId: data?.doctype,
                        recordId: member_id,
                    })
                }
            }
        }

        //console.log(document_files);
        // previous files loop
        let documents = [];
        if (props.show && Array.isArray(props?.list) && props?.list?.length > 0) {
            documents = [...props.list];
        } else if (!props.show && Array.isArray(profileDocuments) && profileDocuments?.length > 0) {
            documents = [...profileDocuments];
        }

        if (documents.length > 0) {
            for (const doc of documents) {
                document_files.push({
                    memberId: member_id,
                    recordTypeId: appConst.recordType.proofUpload,
                    fileName: doc?.fileName,
                    filePath: doc?.filePath,
                    note: data?.note,
                    typeId: data?.doctype,
                    recordId: member_id,
                    id: doc?.id
                })
            }
        }


        if (document_files.length <= 0 && proofDoc?.length <= 0) {
            notifyToast(332);
        } else if (size_validation && type_validation) {
            notifyToast(333);
        } else if (type_validation) {
            notifyToast(330);
        } else if (size_validation) {
            notifyToast(331);
        } else if (name_validation) {
            notifyToast(337);
        } else {
            progParams(true);
            serverPost(apiConst.docupdate, document_files).then((res) => {
                progParams(false);
                try {
                    if (res?.status == 1) {
                        notifyToast(res.messageCode[0]);
                        const enc_documents = sessionStore?.uploadid?.filter(item => item.type !== window.location.pathname);
                        ecnSession(data?.note, "proofnote");
                        ecnSession(data?.doctype, "proofdoctype");
                        ecnSession(enc_documents, "uploadid");
                        if (props?.show) {
                            props?.setShow();  //in dashbord to show list 
                            props?.getList();
                        } else {
                            navigateProfile(); //continue to appointment flow 
                        }
                    }
                    else {
                        var error = JSON.parse(res.data.errorMessage);
                        notifyToast(error.messageCode[0]);
                    }
                } catch (e) {
                    console.log(e);
                }
            })
        }
    }

    const navigateProfile = async () => {
        const visit_detail_page = getSearchParams("isrp"); // visit detail page navigate back to reason page to complete apointment t == yes else null or undefined

        if (visit_detail_page && visit_detail_page == "t") {
            const locationSearch = window.location.search;
            return navigate(`/reason${locationSearch}&isrp=t`);
        } else if (redirectUrl && redirectUrl !== '') {
            const url = JSON.parse(redirectUrl);
            const _isFamily = await getFamilyList();
            if (_isFamily) {
                url.path = url?.familyMemberUrl;
                sessionStorage.setItem("redirectUrl", JSON.stringify(url));
            }
            navigate(`${url?.path}`);
        } else {
            navigate(`/dashboard`);
        }
    }

    const getCateogry = () => {
        progParams(true);
        serverGet(`${apiConst.getcateogry}${appConst.cateogries.proofUpload}`).then((res) => {
            progParams(false);
            try {
                if (res?.status == 1) {
                    setCateogry(res.data);
                    const sessionStore = ecnSession();

                    if (props?.show && props?.list?.length > 0) {
                        const doc_type = res?.data?.filter((item) => item?.name == props?.list[0]?.docType) || []
                        if (doc_type?.length > 0) {
                            setCateogryType(doc_type[0]?.id);
                        }
                        setValue('note', props?.list[0]?.note);
                    } else if (sessionStore?.proofdoctype) {
                        //setValue('note', sessionStore?.proofnote);
                        setCateogryType(sessionStore?.proofdoctype);
                        if (sessionStore?.proofnote)
                            setValue('note', sessionStore?.proofnote);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        })
    }

    const handleBack = (e) => {
        e.preventDefault();
        props?.setShow();
    }

    useEffect(() => {

        const title = props?.show && props?.list?.length > 0 ? "Edit identification detail" : "Add identification detail"
        document.title = title;
        getCateogry();
        if (!props?.show)
            getDocuments();
    }, []);


    useEffect(() => {

        if (cateogryType)
            setValue('doctype', cateogryType);

    }, [cateogryType]);

    // console.log(list);
    //console.log(props);

    return (
        <>
            {!props?.show && <div className="mb-3">
                <ScheduleHeader title={getLanguageContent("idupld")} />
                <small className="text-secondary">{getLanguageContent("plsupld")}</small>
            </div>
            }
            <div className={!props?.show ? "card border-0 p-2" : ""}>
                <div className={!props?.show ? "card-body" : ""}>
                    <div className={`row ${!props?.show ? "justify-content-center" : ""}`}>
                        <div className="col-lg-10 col-xl-9 col-xxl-8">
                            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                <div className="mb-3">
                                    <label htmlFor="doctype" className="form-label">{getLanguageContent("typ")}<span className="text-danger star">*</span></label>
                                    <select autoFocus={true} className="form-control form-select" id="doctype"  {...register("doctype", { required: getMsg(301) })}>
                                        <option value="">{getLanguageContent("select")}</option>
                                        {cateogry?.length > 0 && cateogry?.map((list, i) => (
                                            <option key={i} value={list?.id}>{list?.name}</option>
                                        ))}
                                    </select>
                                    {errors.doctype?.message && <span className="text-danger">{errors.doctype?.message}</span>}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="note" className="form-label">{getLanguageContent("nte")}</label>
                                    <textarea placeholder={getLanguageContent("nte")} className="form-control" id="note" rows="3" {...register("note", { maxLength: { value: 500, message: getMsg("305") } })}></textarea>
                                    {errors.note?.message && <p className="text-danger">{errors.note?.message}</p>}
                                </div>

                                {props?.list?.length > 0 || (proofDoc?.length > 0 ||
                                    (sessionStore?.uploadid?.length > 0 && sessionStore?.uploadid?.filter((list) => list?.type == window.location.pathname)?.length > 0)
                                )

                                    ?
                                    <h6 className="mb-3">{getLanguageContent("docmnt")}</h6> : ""
                                }
                                <div className="row">
                                    {/*{props?.list?.length > 0 && props?.list?.filter((item) => item.statusId == 1)?.map((list, index) => (*/}
                                    {/*    <div className="col-xl-6 mb-3" key={index}>*/}
                                    {/*        <div className="d-flex align-items-center border border-subtle rounded">*/}
                                    {/*            <div className="px-2">*/}
                                    {/*                <svg className="fs-1 text-secondary" width="20" height="20" role="img"><use xlinkHref="#files_icon" /></svg>*/}
                                    {/*            </div>*/}
                                    {/*            <div className="py-1 truncate-text  flex-grow-1">*/}
                                    {/*                {list?.fileName}*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*))}*/}

                                    {!props?.show && Array.isArray(profileDocuments) && profileDocuments.length > 0 ?
                                        <>
                                            {profileDocuments?.filter((item) => item.statusId == 1)?.map((list, index) => (
                                                <div className="col-xl-6 mb-3" key={index}>
                                                    <div className="d-flex align-items-center border border-subtle rounded py-3">
                                                        <div className="px-2">
                                                            <svg className="fs-1 text-secondary" width="20" height="20" role="img"><use xlinkHref="#files_icon" /></svg>
                                                        </div>
                                                        <div className="py-1 truncate-text  flex-grow-1">
                                                            {list?.fileName}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </> :
                                        props?.show && Array.isArray(props?.list) && props?.list?.length > 0 ?
                                            <>
                                                {props?.list?.filter((item) => item.statusId == 1)?.map((list, index) => (
                                                    <div className="col-xl-6 mb-3" key={index}>
                                                        <div className="d-flex align-items-center border border-subtle rounded py-3">
                                                            <div className="px-2">
                                                                <svg className="fs-1 text-secondary" width="20" height="20" role="img"><use xlinkHref="#files_icon" /></svg>
                                                            </div>
                                                            <div className="py-1 truncate-text  flex-grow-1">
                                                                {list?.fileName}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </> : null
                                    }



                                    {/*{show && list?.length > 0 &&*/}
                                    {/*    (proofDoc?.length > 0 ||*/}
                                    {/*        (sessionStore?.uploadid?.length > 0 && sessionStore?.uploadid?.filter((list) => list?.type == window.location.pathname)?.length > 0)*/}
                                    {/*    )*/}
                                    {/*    &&*/}
                                    {/*    <h6>Newly added</h6>*/}
                                    {/*}*/}
                                    {/*<DocumentUpload size={10}*/}
                                    {/*    setDocState={setProofDoc}*/}
                                    {/*    encounter={false}*/}
                                    {/*    fileTypes={appConst.fileTypes.profile.validationTypes}*/}
                                    {/*    disText={appConst.fileTypes.profile.displayText}*/}
                                    {/*    maxFile={5}*/}
                                    {/*    name="identification-doc"*/}
                                    {/*/>*/}

                                    <DocumentUpload
                                        size={10}
                                        //setDocState={setReasonDoc}
                                        //cameraUpload={true}
                                        //encounter={false}
                                        //fileTypes={appConst.fileTypes.reasonCapture.validationTypes}
                                        //disText={appConst.fileTypes.reasonCapture.displayText}
                                        //name="reason-cond"
                                        //setConditionDocs={setConditionDocs}
                                        //conditionDocs={conditionDocs}
                                        //maxFile={conditionDocLength}
                                        //showPreview={false}
                                        // enableCamera={true}
                                        setDocState={setProofDoc}
                                        encounter={false}
                                        fileTypes={appConst.fileTypes.profile.validationTypes}
                                        disText={appConst.fileTypes.profile.displayText}
                                        maxFile={5}
                                        name="identification-doc"
                                    />
                                </div>
                                <div className="text-end">     
                                    {(props?.showProfileDocForm && props?.show) && <button className="btn btn-lg btn-secondary my-4 me-2" onClick={(e) => handleBack(e)}>{getLanguageContent("bck")}</button>}
                                    <button type="submit" className="btn btn-lg btn-primary my-4">{getLanguageContent("submt")}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}